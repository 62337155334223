import initialState from '../initialState';

import {
  FIELD_CHANGE,
  BOOTSTRAP_TOKEN,
} from 'components/SavePassword/constants';

let debug = false;

export default function passwordReducer(state = initialState.forms.password, action) {
  let newState, data, errors;

  switch (action.type) {

    case BOOTSTRAP_TOKEN:
      if (debug) console.log("passwordReducer - BOOTSTRAP_TOKEN");
      data = action.data;

      newState = {
        ...state,
        token: data
      }

      return newState;
    
    case FIELD_CHANGE:
      if (debug) console.log("passwordReducer - FIELD_CHANGE");
      data = action.data;
      let statePassword = state.password;
      let statePasswordConfirm = state.password_confirmation;
      let newValid = false;

      if (data.key === 'password') {
        statePassword = data.value;
      } else if (data.key === 'password_confirmation') {
        statePasswordConfirm = data.value;
      }

      if (statePassword.length > 7 && (statePassword === statePasswordConfirm)) {
        newValid = true;
      }

      newState = {
        ...state,
        password: statePassword,
        password_confirmation:statePasswordConfirm,
        valid: newValid,
      }

      return newState;

    default:
      return state;
  }
}
