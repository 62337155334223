import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  header: {
    background: "-webkit-linear-gradient(-90deg, #ff9319 20%, #fed700 100%)",
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
    paddingBottom: theme.spacing()
  },
  instructions: {
    paddingBottom: theme.spacing()
  },
  custom: theme.customHr
}));

function Header(props) {
  let debug = false;
  const classes = useStyles();

  if (debug) console.log(props);

  const {} = props;

  if (debug) console.log('Header RENDER');

  return (
    <div className={classes.Header}>
      <Typography color="secondary" variant="h5" className={classes.header} align="center">
        Welcome to VetCompanion!
      </Typography>
      <Typography variant="body1" className={classes.instructions} align="center">
        Please complete the step-by-step registration process below.
      </Typography>
      <hr className={classes.custom} />
    </div>
  );
}

Header.propTypes = {};

export default Header;