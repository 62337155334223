import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import FormHelperText from '@material-ui/core/FormHelperText';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({  
  formControl: {
    width: '100%',
  },
  textField: {
    backgroundColor: 'white',
    // marginBottom: theme.spacing(1/2),
    width: '100%',
  }
}));

const returnValue = (savedValue, defaultValue, options) => {
  const optionValues = options.map(el => el.value);
  let returnValue = savedValue || defaultValue;
  if (!optionValues.includes(returnValue)) returnValue = '';
  return returnValue;
}

function InputSelect(props) {
  let debug = false;
  const classes = useStyles();

  if (debug) console.log(props);
  const { changeAction, id, defaultValue, label, description, error, errorMessage, required, options, savedValue, disabled: dropDownDisabled } = props;

  const [value, setValue] = React.useState(returnValue(savedValue, defaultValue, options));

  if (debug) console.log('InputSelect RENDER');

  const handleSelectChange = (key, value) => {
    setValue(value);
    changeAction({ key, value });
  }

  const optionsContent = () => {
    const content = [];
    let options_content;
    options.forEach(option => {
      const {value, label, disabled} = option;
      if (disabled) {
        options_content = <ListSubheader key={label} disableSticky={true}>{label}</ListSubheader>
      } else {
        options_content = <MenuItem key={label} value={value}>{label}</MenuItem>;
      }
      content.push(options_content);
    });
    return content;
  }

  return (
    <FormControl required={required} className={classes.formControl} variant="outlined" error={error}>
      <InputLabel id={`form-field-${id}-label`}>{label}</InputLabel>
      <Select
        labelId={`form-field-${id}-label`}
        className={classes.textField}
        value={value}
        disabled={dropDownDisabled}
        // defaultValue={defaultValue || ''}
        id={id}
        name={id}
        label={label}
        onChange={(event) => {
          handleSelectChange(id, event.target.value);
        }}
        variant="outlined"
      >
        { optionsContent() }
      </Select>
      <FormHelperText dangerouslySetInnerHTML={{ __html: description || errorMessage }} />
    </FormControl>
  );
}

InputSelect.propTypes = {
  changeAction: PropTypes.func.isRequired,
  savedValue: PropTypes.any,
  id: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  label: PropTypes.string.isRequired,
  description: PropTypes.string,
  required: PropTypes.bool,
  options: PropTypes.array
};

export default InputSelect;