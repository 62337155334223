import {subYears, getMonth, getYear} from 'date-fns';

export default (assocData) => {
  
  const yearOptions = [];

  let option_date = new Date();

  let i;
  for (i = 0; i > -61; i--) {
    const option_year = getYear(option_date)
    yearOptions.push({ label: option_year, value: option_year});
    option_date = subYears(option_date, 1);
  }

  const assocStateOptions = [];
  assocData.forEach(state => {
    assocStateOptions.push({
      label: state.name,
      value: state.abbr
    })
  });

  assocStateOptions.push({label: 'Other', value: 'other'})

  return [[
    {
      options: {
        lg: [12,12],
        xs: [12,12]
      },
      fields: [
        {
          id: 'association_state',
          label: 'State or Provincial Association',
          description: '',
          required: true,
          input: 'select',
          options: assocStateOptions,
        },
      ]
    },
    {
      options: {
        lg: [12,12],
        xs: [12,12],
        notIf: {
          field: 'association_state',
          value: 'other'
        }
      },
      fields: [
        {
          id: 'join_year',
          label: 'Membership Join Year',
          description: '',
          required: true,
          input: 'select',
          options: yearOptions,
        },
      ]
    }
  ], yearOptions]
};