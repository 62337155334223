import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Element, animateScroll as scroll, scroller } from 'react-scroll';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import CustomDialog from 'components/CustomDialog';

import Header from '../Shared/Header';
import Side from '../Shared/Side';
import AccountInfo from "components/SignUp/AccountInfo";

import { makeStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: theme.container,
  bgPaper: theme.bgPaper,
  errorList: theme.errorList,
}));

function Info(props) {
  let debug = false;
  const classes = useStyles();
  const [showingDialog, setshowingDialog] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const { history, actions, userData, dialogData, valid, requiredFields, errors, subscriptionCancelled, states } = props;
  
  useEffect(() => {
    if (!showingDialog && errors.length > 0) {
      setErrorDialogInfo();
      actions.toggleDialog();
      setshowingDialog(true);
    }
  });
  
  if (debug) console.log(props);
  if (debug) console.log('Info RENDER');

  const stepContent = () => {
    const accountProps = {
      ...userData,
      activeStep: 2,
      setSubmitting,
      submitting,
      assocStates: [],
      subscriptionType: 'my-account',
      requiredFields,
      history,
      scroller,
      states,
      valid,
      actions
    }
    return ( <AccountInfo {...accountProps} /> )
  }

  const handleDialogClose = () => {
    if (errors.length > 0) {
      actions.resetErrors();
      setshowingDialog(false);
      setSubmitting(false);
    }
    if (subscriptionCancelled) {
      actions.cancelSubscriptionToggle({ cancelled: false, refunded: false });
      setshowingDialog(false);
      setTimeout(() => {
        window.location.reload();
      }, 500);
    }
    actions.toggleDialog();
  };

  const setErrorDialogInfo = () => {
    actions.setDialoInfo({
      title: 'Issues Found',
      body: () => {
        const renderErrors = () => {
          const errorsHtml = [];
          errors.forEach((error, index) => {
            const errorHtml = <li key={`error-${index}`}>{error.message}</li>
            errorsHtml.push(errorHtml);
          });
          return errorsHtml;
        }
        return (
          <ul className={classes.errorList}>
            {renderErrors()}
          </ul>
        )
      }
    });
  }

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Grid container spacing={2}>
        <Grid item lg={8} xs={12}>
          <Element name="scrollPoint" className="element">
            <Header title="My Account" />
          </Element>
          <Paper className={classes.bgPaper}>
            { stepContent() }
          </Paper>
        </Grid>
        <Grid item lg={4} xs={12} className={classes.side}>
          <Side {...props} />
        </Grid>
      </Grid>
      <CustomDialog dialogData={dialogData} handleClose={handleDialogClose} />
    </Container>
  );
}

Info.propTypes = {
  actions: PropTypes.object.isRequired,
  dialogData: PropTypes.object.isRequired,
  personalMessage: PropTypes.string.isRequired,
  phoneInstructions: PropTypes.object.isRequired,
  requiredFields: PropTypes.array.isRequired,
  subscriptionData: PropTypes.object,
  userData: PropTypes.object.isRequired,
  valid: PropTypes.bool.isRequired,
};

export default Info;