import * as types from './constants';

let debug = false;


export function checkPaymentInfoValid() {
  if (debug) console.log("Shared - checkPaymentInfoValid");
  return {
    type: types.CHECK_PAYMENT_INFO_VALID
  };
}

export function getIntentSecret(data) {
  if (debug) console.log("Shared - getIntentSecret");
  return {
    type: types.GET_INTENT_SECRET,
    data
  };
}

export function getPromoCode() {
  if (debug) console.log("SignUp - getPromoCode");
  return {
    type: types.GET_PROMO_CODE,
  };
}

export function sendSubscription(data) {
  if (debug) console.log("Shared - sendSubscription");
  return {
    type: types.SUBSCRIPTION_SEND,
    data
  };
}

export function removePromo(data) {
  if (debug) console.log("Shared - removePromo");
  return {
    type: types.REMOVE_PROMO_CODE,
    data
  };
}

export function resetErrors(data) {
  if (debug) console.log("Shared - resetErrors");
  return {
    type: types.RESET_ERRORS,
    data
  };
}

export function setPaymentMethodId(data) {
  if (debug) console.log("Shared - setPaymentMethodId");
  return {
    type: types.SET_PAYMENT_METHOD,
    data
  };
}

export function setStripeValid(data) {
  if (debug) console.log("Shared - setStripeValid");
  return {
    type: types.SET_STRIPE_VALID,
    data
  };
}

export function setSubmitting(data) {
  if (debug) console.log("Shared - setSubmitting");
  return {
    type: types.SET_SUBMITTING,
    data
  };
}

export function subscriptionError(data) {
  if (debug) console.log("Shared - subscriptionError");
  return {
    type: types.SUBSCRIPTION_SEND_ERROR,
    data
  };
}

export function step3FieldChange(data) {
  if (debug) console.log("SignUp - step3FieldChange");
  return {
    type: types.STEP_3_FIELD_CHANGE,
    data
  };
}

export function getPaymentIntent() {
  if (debug) console.log("Shared - getPaymentIntent");
  return {
    type: types.GET_INTENT_SECRET,
  };
}

export function toggleDialog() {
  if (debug) console.log("Shared - toggleDialog");
  return {
    type: types.TOGGLE_DIALOG,
  };
}

export function setDialoInfo(data) {
  if (debug) console.log("Shared - setDialoInfo");
  return {
    type: types.SET_DIALOG_INFO,
    data
  };
}

export function setbillingInterval(data) {
  if (debug) console.log("Shared - setbillingInterval");
  return {
    type: types.SET_BILLING_INTERVAL,
    data
  };
}

export function setQuantity(data) {
  if (debug) console.log("Shared - setQuantity");
  return {
    type: types.SET_QUANTITY,
    data
  };
}