import React from 'react';
import PropTypes from 'prop-types';

import TextField from '@material-ui/core/TextField';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  textField: {
    marginBottom: theme.spacing(1/2),
    width: '100%',
  }
}));

function InputText(props) {
  let debug = false;
  const classes = useStyles();

  if (debug) console.log(props);
  const { changeAction, emailCheckAction, error, errorMessage, id, disabled, label, description, required, options, savedValue, type, subscriptionType, readonly, autoComplete } = props;

  const [text_value, setValue] = React.useState(savedValue);
  const timeouts = {} 

  if (debug) console.log('InputText RENDER');

  const handleValueChange = (key, value) => {
    setValue(value);
    clearTimeout(timeouts[key]);
    timeouts[key] = setTimeout(() => {
      changeAction({ key, value });
    }, 250);
  }

  const handleOnBlur = (key, value) => {
    if (key === 'promoCode') return false;
    changeAction({ key, value });
    setTimeout(() => {
      if (subscriptionType === 'student-sub' && key === 'email') {
        emailCheckAction(value);
      }
    }, 250);
  }
  
  return (
    <TextField
      className={classes.textField}
      helperText={errorMessage || description}
      autoComplete={autoComplete}
      id={id}
      name={id}
      required={required}
      type={type || 'text'}
      disabled={disabled || false}
      // inputProps={{ minLength: '10', maxLength: '90' }}
      label={label}
      error={error}
      onChange={(event) => {
        handleValueChange(id, event.target.value);
      }}
      onBlur={(event) => {
        handleOnBlur(id, event.target.value);
      }}
      value={text_value || savedValue}
      variant="outlined"
      InputProps={{
        readOnly: readonly,
      }}
    />
  );
}

InputText.propTypes = {
  changeAction: PropTypes.func.isRequired,
  savedValue: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  label: PropTypes.string.isRequired,
  description: PropTypes.string,
  required: PropTypes.bool,
  options: PropTypes.array
};

export default InputText;