import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ConfirmPassword from './ConfirmPassword';

import * as actions from 'components/SavePassword/actions';
import * as sharedActions from 'components/Shared/actions';
let debug = false;

function mapStateToProps(state) {
  if (debug) console.log('ConfirmPassword - mapStateToProps');
  return {
    dialogData: state.globalReducer.dialog,
    errors: state.globalReducer.errors,
    passwordData: state.passwordFormReducer,
  };
}

function mapDispatchToProps(dispatch) {
  if (debug) console.log('ConfirmPassword - mapDispatchToProps');
  return {
    actions: bindActionCreators(actions, dispatch),
    sharedActions: bindActionCreators(sharedActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmPassword);