import React, { useEffect } from 'react';
import { Element, animateScroll as scroll, scroller } from 'react-scroll';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import Header from '../Shared/Header';
import Side from '../Shared/Side';
import PaymentInfo from "components/SignUp/PaymentInfo";
import FinishScreen from "components/SignUp/FinishScreen";

import { makeStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: theme.container,
  bgPaper: theme.bgPaper,
  mobilePaper: theme.mobilePaper,
}));

function Subscribe(props) {
  let debug = false;
  const classes = useStyles();

  if (debug) console.log(props);

  const { actions, defaultStep, dialogData, history, progressData, section, step1Data, step3Data, step4Data, globalErrors, assocStates } = props;

  const { step } = props.match.params;
  const hashStep = step;
  const { step: activeStep } = progressData;

  useEffect(() => {
    if (!hashStep) {
      history.replace(`/${defaultStep}`)
      return false;
    }
  });

  const stepContent = () => {
    switch (true) {
      case hashStep === "1":
        const step3Props = {
          ...step3Data,
          assocStates,
          invoice: step4Data.invoice,
          activeStep,
          history,
          subscriptionType: step1Data.subscription_type.value,
          dialogData,
          section,
          scroller,
          actions
        }
        return ( <PaymentInfo {...step3Props} /> )
      case hashStep && hashStep.startsWith("in_"):
        const step4Props = {
          ...step4Data,
          step3done: step3Data.done,
          dialogData,
          actions,
          section,
          globalErrors,
          params: props.match.params,
        }
        return ( <FinishScreen {...step4Props} /> )
      default:
        break;
    }
  }

  if (debug) console.log('Subscribe RENDER');

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Grid container spacing={2}>
        <Grid item lg={8} xs={12}>
          <Element name="scrollPoint" className="element">
            <Header title="Add a Subscription" />
          </Element>
          <Paper className={classes.bgPaper}>
            { stepContent() }
          </Paper>
        </Grid>
        <Grid item lg={4} xs={12} className={classes.side}>
          <Side {...props} />
        </Grid>
      </Grid>
    </Container>
  );
}

Subscribe.propTypes = {

};

export default Subscribe;