import * as types from './constants';

let debug = false;

export function addRecentGraduate(data) {
  if (debug) console.log("SignUp - addRecentGraduate");
  return {
    type: types.ADD_RECENT_GRADUATE,
    data
  };
}

export function addGraduatePlanToInvoice(data) {
  if (debug) console.log("SignUp - addGraduatePlanToInvoice");
  return {
    type: types.ADD_GRADUATE_PLAN_TO_INVOICE,
    data
  };
}

export function addVMAPlanToInvoice(data) {
  if (debug) console.log("SignUp - addVMAPlanToInvoice");
  return {
    type: types.ADD_VMA_PLAN_TO_INVOICE,
    data
  };
}

export function addGraduatePlanYear(data) {
  if (debug) console.log("SignUp - addGraduatePlanYear");
  return {
    type: types.ADD_GRADUATE_PLAN_YEAR,
    data
  };
}

export function addVMAStateYear(data) {
  if (debug) console.log("SignUp - addVMAStateYear");
  return {
    type: types.ADD_VMA_STATE_YEAR,
    data
  };
}

export function setStripeValid(data) {
  if (debug) console.log("SignUp - setStripeValid");
  return {
    type: types.SET_STRIPE_VALID,
    data
  };
}

export function checkPaymentInfoValid() {
  if (debug) console.log("SignUp - checkPaymentInfoValid");
  return {
    type: types.CHECK_PAYMENT_INFO_VALID
  };
}

export function initiatePlans(data) {
  if (debug) console.log("SignUp - initiatePlans");
  return {
    type: types.INITIATE_PLAN_ITEMS,
    data
  };
}

export function setAccountRequired(data) {
  if (debug) console.log("SignUp - setAccountRequired");
  return {
    type: types.SET_STEP_2_REQUIRED,
    data
  };
}

export function step1FieldChange(data) {
  if (debug) console.log("SignUp - step1FieldChange");
  return {
    type: types.STEP_1_FIELD_CHANGE,
    data
  };
}

export function accountFieldChange(data) {
  if (debug) console.log("SignUp - accountFieldChange");
  if (debug) console.log(data);
  return {
    type: types.STEP_2_FIELD_CHANGE,
    data
  };
}

export function checkStudentEmail(data) {
  if (debug) console.log("SignUp - checkStudentEmail");
  if (debug) console.log(data);
  return {
    type: types.STUDENT_EMAIL_CHECK,
    data
  };
}

export function step3FieldChange(data) {
  if (debug) console.log("SignUp - step3FieldChange");
  return {
    type: types.STEP_3_FIELD_CHANGE,
    data
  };
}

export function getInvoice() {
  if (debug) console.log("SignUp - getInvoice");
  return {
    type: types.GET_INVOICE,
  };
}

export function getInvoiceById(data) {
  if (debug) console.log("SignUp - getInvoiceById");
  return {
    type: types.GET_INVOICE_BY_ID,
    data
  };
}

export function invoiceChanged(data) {
  if (debug) console.log("SignUp - invoiceChanged");
  return {
    type: types.INVOICE_CHANGED,
    data
  };
}

export function resetErrors(data) {
  if (debug) console.log("Shared - resetErrors");
  return {
    type: types.RESET_ERRORS,
    data
  };
}

export function setSubscriptionType(data) {
  if (debug) console.log("SignUp - setSubscriptionType");
  return {
    type: types.SET_SUBSCRIPTION_TYPE,
    data
  };
}

export function setStep(data) {
  if (debug) console.log("SignUp - setStep");
  return {
    type: types.SET_STEP,
    data
  };
}

export function toggleAgreement(data) {
  if (debug) console.log("SignUp - toggleAgreement");
  return {
    type: types.TOGGLE_AGREEMENT,
    data
  };
}

