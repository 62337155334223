import { createMuiTheme } from '@material-ui/core/styles';

import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import createPalette from '@material-ui/core/styles/createPalette';

const palette = createPalette({});

const breakpoints = createBreakpoints({
  values: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1170,
    xl: 1620,
  }
})

const shared = {
  spacing: (m) => `${15 * m}px`,
  palette: {
    ...palette,
    primary: {
      main: '#337ab7'
    },
    secondary: {
      main: '#ff9319'
    }
  },
  breakpoints: breakpoints
}

// A custom theme for this app
const theme = createMuiTheme({
  ...shared,
  typography: {
    fontFamily: '"myriad-pro", "Open Sans", sans-serif',
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        html: {
          fontSize: '20px',
          boxSizing: 'border-box',
          minHeight: '100vh',
        },
        body: {
          backgroundColor: '#FFF',
          fontFamily: '"myriad-pro", "Open Sans", sans-serif',
          fontSize: '14px',
          minHeight: '100vh',
          WebkitFontSmoothing: 'antialiased',
          MozFontSmoothing: 'antialiased'
        },
      },
    },
    MuiTypography: {
      body1: {
        fontFamily: '"myriad-pro", "Open Sans", sans-serif',
        fontSize: '14px',
      },
      body2: {
        fontWeight: 300,
      },
      body3: {
        fontFamily: '"myriad-pro", "Open Sans", sans-serif',
        fontSize: '16px',
        fontWeight: 300,
      },
      h4: {
        fontWeight: 700
      },
      h5: {
        fontWeight: 700
      },
      h6: {
        fontFamily: '"myriad-pro", "Open Sans", sans-serif',
        fontWeight: 300,
      },
      subtitle1: {
        fontFamily: '"myriad-pro", "Open Sans", sans-serif',
        fontWeight: 300,
      },
      colorTextSecondary: {
        color: '#888',
      }
    },
    MuiListItemText: {
      secondary: {
        fontSize: '14px',
      }
    },
    MuiButton: {
      root: {
        borderRadius: 0,
        fontWeight: 500,
        fontSize: '14px',
        textTransform: 'unset',
      },
      containedPrimary: {
        borderColor: '#2e6da4',
        '&:hover': {
          color: '#FFF',
        },
        '&:focus': {
          color: '#FFF',
        },
      }
    },
    MuiInputBase: {
      root: {
        backgroundColor: shared.palette.background.paper,
        fontSize: '.875rem',
      }
    },
    MuiPaper: {
      root: {
        backgroundColor: '#f7f7f7',
        padding: shared.spacing(2),
      }
    },
    MuiStepLabel: {
      label: {
        fontSize: '14px'
      }
    },
    MuiInputLabel: {
      outlined: {
        fontSize: '0.875rem',
        fontWeight: '300',
      }
    },
    MuiFormControl: {
      root: {
        backgroundColor: 'transparent'
      }
    },
    MuiTableCell: {
      root: {
        borderBottom: 0,
        fontWeight: 300,
        padding: 10,
        paddingBottom: 8,
        paddingTop: 15,
        position: 'relative',
        verticalAlign: 'bottom'
      },
      head: {
        textTransform: 'uppercase',
        fontWeight: 600,
      }
    },
    MuiTableHead: {
      root: {
        borderBottom: '1px solid #BBB',
        // fontVariant: 'small-caps'
        fontWeight: '600'
      }
    },
    MuiTableRow: {
      root: {
        borderBottom: '1px solid #DDD',
        position: 'relative',
        "&:last-child": {
          borderBottom: 0,
        },
      }
    },
    MuiFormHelperText: {
      root: {
        // marginTop: shared.spacing(-.5),
        marginBottom: shared.spacing(.5),
        "&:empty": {
          marginBottom: 0,
        }
      }
    },
    MuiSwitch: {
      input: {
        left: '-150%',
        width: '400%'
      }
    },
  },
  customHr: {
    marginBottom: shared.spacing(2),
    marginTop: shared.spacing(2),
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: shared.spacing(2),
    marginBottom: shared.spacing(1),
    position: 'relative',
    '& button': {
      '&:last-child': {
        marginLeft: shared.spacing(.5)
      },
    }
  },
  errorList: {
    listStyle: 'decimal',
    paddingLeft: shared.spacing(1),
    "& li": {
      marginBottom: shared.spacing(.5),
      textTransform: 'uppercase',
    }
  },
  buttonProgress: {
    color: 'primary',
    left: '50%',
    marginLeft: -12,
    position: 'absolute',
  },
  subtitle: {
    marginBottom: shared.spacing(1),
    marginTop: shared.spacing(1),
  },
  subtitle2: {
    marginBottom: shared.spacing(2),
    marginTop: shared.spacing(3),
  },
  defaultPaper: {
    width: '100%',
    backgroundColor: shared.palette.background.paper,
    paddingTop: 0,
    paddingBottom: 0,
  },
  container: {
    margin: '50px auto 100px auto',
    padding: shared.spacing(1),
  },
  bgPaper: {
    marginTop: shared.spacing(2),
  },
  mobilePaper: {
    padding: shared.spacing(1)
  },
});
export default theme;
