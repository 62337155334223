import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Element, animateScroll as scroll, scroller } from 'react-scroll';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import Header from './Header';
import StepConnector from './StepConnector';
import StepperIcons from "./StepperIcons";
import SubscriptionType from "./SubscriptionType";
import AccountInfo from "./AccountInfo";
import PaymentInfo from "./PaymentInfo";
import FinishScreen from "./FinishScreen";

import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { Paper } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: theme.container,
  bgPaper: theme.bgPaper,
  mobilePaper: theme.mobilePaper,
  sideButton: {
    marginTop: theme.spacing(),
    '&:hover': {
      color: theme.palette.primary.contrastText,
    },
    '&:focus': {
      color: theme.palette.primary.contrastText,
    }
  },
  studentMessage: {
    marginTop: theme.spacing(2),
    marginBottom: 0,
    fontSize: '1rem',
    textAlign: 'center',
  },
  resendButton: {
    marginTop: theme.spacing(),
  },
  side: {
    marginTop: theme.spacing(1.2),
  },
  stepper: {
    backgroundColor: '#FFF',
  },
  custom: theme.customHr
}));

function SignUp(props) {
  let debug = false;
  const classes = useStyles();
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('md'));

  const { actions, assocStates, defaultStep, dialogData, history, progressData, section, step1Data, step2Data, step3Data, step4Data, globalErrors, products, coupons, userData, states } = props;
  
  const { step } = props.match.params;
  const hashStep = step;
  const { step: activeStep } = progressData;
  
  useEffect(() => {
    if (!hashStep) {
      history.replace(`/${defaultStep}`)
      return false;
    }
  });
  
  if (debug) console.log(props);
  if (debug) console.log('SignUp RENDER');
  
  const steps = ['Subscription Type', 'Account Info', 'Plan and Payment', 'Finish'];
  const stepContent = () => {
    switch (true) {
      case hashStep === "1":
        const step1Props = {
          ...step1Data,
          assocStates,
          dialogData,
          history,
          section,
          userData,
          products,
          coupons,
          actions
        }
        return ( <SubscriptionType {...step1Props} /> )
      case hashStep === "2":
        const step2Props = {
          ...step2Data,
          activeStep,
          assocStates,
          subscriptionType: step1Data.subscription_type.value,
          history,
          scroller,
          states,
          actions
        }
        return ( <AccountInfo {...step2Props} /> )
      case hashStep === "3":
        const step3Props = {
          ...step3Data,
          invoice: step4Data.invoice,
          activeStep,
          assocStates,
          history,
          subscriptionType: step1Data.subscription_type.value,
          dialogData,
          section,
          scroller,
          actions
        }
        return ( <PaymentInfo {...step3Props} /> )
      case hashStep && hashStep.startsWith("in_"):
        const step4Props = {
          ...step4Data,
          step3done: step3Data.done,
          dialogData,
          actions,
          globalErrors,
          section,
          params: props.match.params,
        }
        return ( <FinishScreen {...step4Props} /> )
      default:
        break;
    }
  }

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Grid container spacing={2}>
        <Grid item lg={8} xs={12}>
          <Header />
          <Element name="scrollPoint" className="element">
            <Stepper alternativeLabel className={classes.stepper} connector={<StepConnector />}activeStep={activeStep - 1}>
              {steps.map((label, i) =>
                <Step key={i}>
                  <StepLabel StepIconComponent={StepperIcons}>
                    {label}
                  </StepLabel>
                </Step>
              )}
            </Stepper>
          </Element>
          { step1Data.subscription_type.value === 'student-sub' && hashStep === "2"
            ? <p className={classes.studentMessage}>Are you an intern or resident? If so, please <a href="/contact">contact us</a> for assistance.</p>
            : null
          }
          <Paper className={mobileView ? classes.mobilePaper : classes.bgPaper}>
            { stepContent() }
          </Paper>
        </Grid>
        <Grid item lg={4} xs={12} className={classes.side}>
          <Typography variant="body1" className={classes.instructions}>
            Already have an account?
          </Typography>
          <Button
              variant="contained"
              color="primary"
              href="/users/sign_in"
              className={classes.sideButton}
            >
              Login now
          </Button>
          <hr className={classes.custom} />
          <Typography variant="body1" className={classes.instructions}>
            Didn't receive confirmation instructions?
          </Typography>
          <Button
            className={classes.resendButton}
            variant="outlined"
            color="primary"
            href="/users/confirmation/new"
          >
            Resend now
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
}

SignUp.propTypes = {
  actions: PropTypes.object.isRequired,
  assocStates: PropTypes.array,
  dialogData: PropTypes.object.isRequired,
  progressData: PropTypes.object.isRequired,
  step1Data: PropTypes.object.isRequired,
  step2Data: PropTypes.object.isRequired,
  step3Data: PropTypes.object.isRequired,
};

export default SignUp;