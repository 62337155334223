import 'whatwg-fetch';
import { put, call } from 'redux-saga/effects';
// import * as appTypes from 'containers/App/constants';

async function parseJSON(response) {
  const body = await response.json();
  if (!body.status) {
    body.status = response.status;
  }
  return body;
}

function request(url, options) {
  return fetch(url, options)
    .then(parseJSON)
}

export default function* callApi(queryURL, opts, failure_type) {
  let debug = false;

  if (debug) console.log("SAGA HELPER - callApi");
  if (debug) console.log(queryURL, opts);
  
  try {
    const payload = yield call(request, queryURL, opts);

    if (debug) console.log(payload);

    if (payload.status === 200) {
      if (debug) console.log('SAGA HELPER - CALL SUCCEEDED');
      return payload;
    } else if (payload.status === 400 || payload.status === 401) {
      if (debug) console.log('SAGA HELPER - CALL 400');
      yield put({ type: failure_type, data: payload });
    }  else if (payload.status === 422) {
      if (debug) console.log('SAGA HELPER - CALL 422');
      const errorKeys = Object.keys(payload.errors);
      const parsedErrors = errorKeys.reduce((acc, cv) => {
        const errArr = payload.errors[cv];
        const newObject = {};
        newObject.message = `${cv.replace('_', ' ')} ${errArr.join(' and ')}`;
        acc.push(newObject);
        return acc;
      }, []);
      yield put({ type: failure_type, data: { errors: parsedErrors } });
    } else {
      yield put({ type: failure_type, data: { errors: [{message: 'Unable to process request.'}]}});
    }
  } catch (error) {
    if (debug) console.log(error);
    throw error;
  }
}