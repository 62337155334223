import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Info from './Info';

import * as actions from './actions';
import * as sharedActions from 'components/Shared/actions';
let debug = false;

function mapStateToProps(state) {
  if (debug) console.log('Info - mapStateToProps');
  return {
    dialogData: state.globalReducer.dialog,
    errors: state.globalReducer.errors,
    globalErrors: state.globalReducer.errors,
    futureSubscription: state.globalReducer.future_subscription,
    personalMessage: state.myAccountFormReducer.personalMessage,
    phoneInstructions: state.myAccountFormReducer.phoneInstructions,
    requiredFields: state.myAccountFormReducer.requiredFields,
    subscriptionData: state.myAccountFormReducer.subscription,
    userData: state.myAccountFormReducer.user,
    valid: state.myAccountFormReducer.valid,
    subscriptionCancelled: state.myAccountFormReducer.subscriptionCancelled,
    subscriptionRefunded: state.myAccountFormReducer.subscriptionRefunded,
    states: state.globalReducer.states,
  };
}

function mapDispatchToProps(dispatch) {
  if (debug) console.log('Info - mapDispatchToProps');

  const subscribeActions = bindActionCreators(actions, dispatch);
  const commonActions = bindActionCreators(sharedActions, dispatch);

  return {
    actions: {
      ...subscribeActions,
      ...commonActions,
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Info);