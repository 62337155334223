import {addYears, getYear} from 'date-fns';

export default () => {

  const yearOptions = [];
  
  let option_date = new Date();
  
  let i;
  for (i = 0; i < 8; i++) {
    const option_year = getYear(option_date)
    yearOptions.push({ label: option_year, value: option_year});
    option_date = addYears(option_date, 1);
  }
  
  return [{
    options: {
      lg: [6,6],
      xs: [12,12]
    },
    fields: [
      {
        id: 'first_name',
        label: 'First Name',
        description: '',
        required: true,
        input: 'text',
        options: [],
      },
      {
        id: 'last_name',
        label: 'Last Name',
        description: '',
        required: true,
        input: 'text',
        options: [],
      },
    ]
  },
  {
    options: {
      lg: [12],
      xs: [12]
    },
    fields: [
      {
        id: 'profession',
        label: 'Profession',
        description: 'User Type (please choose one)',
        required: false,
        input: 'select',
        options: [
          { value: 'Veterinary student', label: 'Veterinary student'},
          { value: 'Veterinary technician/nursing student', label: 'Veterinary technician/nursing student'},
        ],
      },
    ]
  },
  {
    options: {
      lg: [12],
      xs: [12]
    },
    fields: [
      {
        id: 'phone_mobile',
        label: 'Phone (mobile)',
        description: '',
        required: true,
        input: 'text',
        options: [],
      },
    ]
  },
  {
    options: {
      lg: [12],
      xs: [12]
    },
    fields: [
      {
        id: 'email',
        label: 'School Email Address',
        description: '*must be an email address from an official school domain name.  If your school does not come up, please contact us.',
        required: true,
        input: 'text',
        options: [],
      },
    ]
  },
  {
    options: {
      lg: [12],
      xs: [12]
    },
    fields: [
      {
        id: 'school_name',
        label: 'School Name',
        disabled: false,
        readonly: true,
        description: '',
        required: true,
        input: 'text',
        options: [],
      },
    ]
  },
  {
    options: {
      lg: [7,5],
      xs: [12,12]
    },
    fields: [
      {
        id: 'graduation_month',
        label: 'Graduation Month',
        description: '',
        required: true,
        input: 'select',
        options: [
          { label: 'Jan (01)', value: '1' },
          { label: 'Feb (02)', value: '2' },
          { label: 'Mar (03)', value: '3' },
          { label: 'Apr (04)', value: '4' },
          { label: 'May (05)', value: '5' },
          { label: 'June (06)', value: '6' },
          { label: 'July (07)', value: '7' },
          { label: 'Aug (08)', value: '8' },
          { label: 'Sep (09)', value: '9' },
          { label: 'Oct (10)', value: '10' },
          { label: 'Nov (11)', value: '11' },
          { label: 'Dec (12)', value: '12' }
        ],
      },
      {
        id: 'graduation_year',
        label: 'Graduation Year',
        description: '',
        required: true,
        input: 'select',
        options: yearOptions,
      },
    ]
  }]
};
