import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import CustomDialog from 'components/CustomDialog';
import BillingCycle from 'components/BillingCycle';
import CreditCard from 'components/CreditCard';
import SubscriptionInvoice from 'components/SubscriptionInvoice';
import CircularProgress from '@material-ui/core/CircularProgress';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const useStyles = makeStyles((theme) => ({
  root: theme.defaultPaper,
  container: {
    margin: '50px auto 100px auto',
    padding: theme.spacing(1),
  },
  buttonContainer: {
    ...theme.buttonContainer,
    justifyContent: 'space-around',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  buttonContainer2: {
    ...theme.buttonContainer,
    justifyContent: 'space-around',
    marginBottom: theme.spacing(2)
  },
  buttonProgress: theme.buttonProgress,
  subtitle: theme.subtitle,
  subtitle2: theme.subtitle2,
  errorList: theme.errorList
}));

function Admin(props) {
  let debug = false;
  const classes = useStyles();

  if (debug) console.log(props);

  const [plansSet, setPlans] = useState(false);
  const [showingErrors, setShowingErrors] = useState(false);

  const { paymentInfo, actions, subscriptionType, dialogData, userData, errors, assocStates } = props;
  const { billingInterval, valid, totals, plans, customerCoupons, subscriptionCoupons, adminSendInvoiceValid, gettingPaymentMethod, intentClientSecret, submitting, addVMAYear, addVMAState, promoError, promoSuccess } = paymentInfo;

  const handleSendInvoiceClick = () => {
    actions.sendSubscription({collection_method: 'send_invoice'});
  }

  const handleChargeClick = () => {
    actions.setSubmitting(true);
    actions.getPaymentIntent();
  }

  const handleDialogClose = () => {
    if (errors.length > 0) {
      actions.resetErrors();
      setShowingErrors(false);
    }
    actions.toggleDialog();
  };

  const setErrorDialogInfo = () => {
    actions.setDialoInfo({
      title: 'Errors with submission',
      body: () => {
        const renderErrors = () => {
          const errorsHtml = [];
          errors.forEach((error, index) => {
            const errorHtml = <li key={`error-${index}`}>{error.message}</li>
            errorsHtml.push(errorHtml);
          });
          return errorsHtml;
        }
        return (
          <ul className={classes.errorList}>
            {renderErrors()}
          </ul>
        )
      }
    });
  }

  const subscriptionInvoiceProps = {
    dialogData,
    assocStates,
    addGraduateYear: '',
    actions,
    plans,
    totals,
    billingInterval,
    subscriptionType,
    customerCoupons,
    subscriptionCoupons,
    userData,
    addVMAYear,
    addVMAState,
    promoError,
    promoSuccess
  }

  const creditCardProps = {
    actions,
    gettingPaymentMethod,
    intentClientSecret,
    submitting
  }

  const billingCycleProps = {
    actions,
    billingInterval,
    subscriptionType: "admin"
  }

  useEffect(() => {
    // the first time the component loads, generate the plans from their previous selections in state
    if (!plansSet) {
      actions.initiateAdminPlans();
      setPlans(true);
    }
    if (!showingErrors && errors.length > 0) {
      setErrorDialogInfo();
      actions.toggleDialog();
      setShowingErrors(true);
    }
  });

  if (debug) console.log('Admin RENDER');
  if (debug) console.log('submitting', submitting);

  const [stripePromise, setStripePromise] = useState(() => loadStripe(process.env.STRIPE_PUBLISHABLE_KEY));

  return (
    <Elements stripe={stripePromise}>
      <Container maxWidth="md" className={classes.container}>
        <Paper>
          <Typography variant='subtitle1' align="center" className={classes.subtitle}>
            Billing Cycle
          </Typography>
          <BillingCycle {...billingCycleProps} />

          <Typography variant='subtitle1' align="center" className={classes.subtitle2}>
            Subscription Info
          </Typography>
          <SubscriptionInvoice {...subscriptionInvoiceProps} />
          <Divider />

          <Typography variant='subtitle1' align="center" className={classes.subtitle2}>
            Credit/Debit Card Information
          </Typography>
          <CreditCard {...creditCardProps} />

          <div className={classes.buttonContainer}>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={handleChargeClick}
              disabled={valid ? submitting || false : true}
            >
              Charge Now
              { submitting && <CircularProgress size={24} className={classes.buttonProgress} /> }
            </Button>
          </div>
          <Divider />

          <Typography variant='subtitle1' align="center" className={classes.subtitle2}>
            Or
          </Typography>
          <div className={classes.buttonContainer2}>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={handleSendInvoiceClick}
              disabled={!adminSendInvoiceValid}
            >
              Create Invoice
            </Button>
          </div>

        </Paper>
        <CustomDialog dialogData={dialogData} handleClose={handleDialogClose} />
      </Container>
    </Elements>
  );
}

Admin.propTypes = {
  actions: PropTypes.object.isRequired,
  dialogData: PropTypes.object.isRequired,
  progressData: PropTypes.object.isRequired,
  paymentInfo: PropTypes.object.isRequired,
  paymentInfo: PropTypes.object.isRequired,
  subscriptionType: PropTypes.string.isRequired,
};

export default Admin;