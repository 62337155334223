import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles, withStyles } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiPaper-root': {
      padding: 0,
      width: '100%',
    },
    '& .MuiDialogContent-root > div': {
      marginBottom: theme.spacing(1/2)
    },
    '& .MuiDialogContent-root > div:last-child': {
      marginBottom: 0
    },
  }
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    // padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1/2),
    color: theme.palette.grey[500],
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

function CustomDialog(props) {
  let debug = false;
  if (debug) console.log(props);

  let title;
  let body = () => {};
  
  const classes = useStyles();
  const { dialogData, handleClose } = props;
  const { info, show } = dialogData;
  const { id } = info;

  if (debug) console.log('CustomDialog RENDER');

  if (info.title) title = info.title;
  if (info.body) body = info.body;

  return (
    <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={show} className={classes.dialog}>
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        { title }
      </DialogTitle>
      <DialogContent dividers>
        { body(id) }
      </DialogContent>
      <DialogActions>
        { info.button
          ? <Button onClick={() => { info.button.onClick(id) }} variant={info.button.variant} color="primary">{info.button.text}</Button>
          : <Button autoFocus onClick={handleClose} color="primary">OK</Button>
        }
      </DialogActions>
    </Dialog>
  );
}

CustomDialog.propTypes = {
  dialogData: PropTypes.object.isRequired,
};

export default CustomDialog;