import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Switch from '@material-ui/core/Switch';
import { purple } from '@material-ui/core/colors';

import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';


const useStyles = makeStyles((theme) => ({
  billingCycle: {
    textAlign: 'center',
    marginBottom: theme.spacing(1),
  },
  billingSelected: {
    fontWeight: '600',
  },
  intervalSwitch: {},
  mobileSwitch: {
    // maxWidth: '100px',
    // '&$checked': {
    //   color: purple[500],
    //   transform: 'translateX(calc(100px - 48px))',
    // },
  }
}));

function BillingCycle(props) {
  let debug = false;
  const classes = useStyles();
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('md'));

  const PurpleSwitch = withStyles({
    root: {
      width: mobileView ? '100px' : '120px',
      height: '50px',
      padding: '18px 12px',
    },
    switchBase: {
      color: purple[500],
      top: '2px',
      '&$checked': {
        color: purple[500],
        transform: mobileView ? 'translateX(calc(100px - 48px))' : 'translateX(calc(120px - 48px))',
      },
      '&$checked + $track': {
        backgroundColor: purple[500],
      },
    },
    thumb: {
      width: 28,
      height: 28,
    },
    checked: {},
    track: {
      backgroundColor: purple[500],
    },
  })(Switch);

  if (debug) console.log(props);
  
  const {actions, billingInterval, subscriptionType, billingIntervalDisabled} = props;
  const [monthly, setMonthly] = useState(billingInterval === 'month' ? true : false);
  
  const handleSwitchChange = () => {
    setMonthly(!monthly);
    actions.setbillingInterval(!monthly);
    if (subscriptionType === 'admin') {
      actions.adminInvoiceChanged();
    } else {
      actions.invoiceChanged();
    }
  }

  if (debug) console.log('BillingCycle RENDER');

  useEffect(() => {
    setMonthly(billingInterval === 'month' ? true : false);
  });

  return (
    <>
      <Grid container alignItems="center" spacing={1} className={classes.billingCycle}>
        <Grid item lg={4} xs={4}>
          <Typography variant='subtitle1' align="right" className={ monthly ? null : classes.billingSelected }>
            Yearly
          </Typography>
        </Grid>
        <Grid item lg={4} xs={4}>
          <PurpleSwitch className={ mobileView ? classes.mobileSwitch : classes.intervalSwitch} checked={monthly} onChange={handleSwitchChange} disabled={billingIntervalDisabled} />
        </Grid>
        <Grid item lg={4} xs={4}>
          <Typography variant='subtitle1' align="left" className={ monthly ? classes.billingSelected : null }>
            Monthly
          </Typography>
        </Grid>
      </Grid>
      <Divider />
    </>
  );
}

BillingCycle.propTypes = {
  actions: PropTypes.object.isRequired,
  billingInterval: PropTypes.string.isRequired,
};

export default BillingCycle;