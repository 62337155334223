import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import HelpIcon from '@material-ui/icons/Help';

import Typography from '@material-ui/core/Typography';

import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';

import { makeStyles } from '@material-ui/core/styles';

import CustomDialog from 'components/CustomDialog';

const useStyles = makeStyles((theme) => ({
  root: theme.defaultPaper,
  buttonContainer: theme.buttonContainer,
  discountList: {
    lineHeight: theme.spacing(2),
    marginTop: theme.spacing()
  }
}));

function SubscriptionType(props) {
  let debug = false;
  const classes = useStyles();
  
  if (debug) console.log(props);
  
  const { actions, assocStates, dialogData, history, subscription_type, valid, products } = props;
  const { value } = subscription_type;

  const [checked, setChecked] = useState([value]);
  const [fieldsReset, setFieldsReset] = useState(false);

  useEffect(() => {
    if (!fieldsReset) {
      actions.resetRequired();
      setFieldsReset(true)
      return false;
    }
  });

  const getPrice = (product, interval, useDecimal) => {
    let price = 0;
    const target_product = products.find(el => el.plan_type === product);

    if (target_product) {
      price = target_product.prices.find(el => el.interval === interval).amount || 0
    }

    if (useDecimal) {
      return `$${(price / 100).toFixed(2)}/${interval}`
    } else {
      return `$${(price / 100)}/${interval}`
    }

  }
  

  const getBulkDiscountList = () => {
    const content = [];
    content.push(<li key="5"><strong>5% discount:</strong> 2-3 Users </li>)
    content.push(<li key="10"><strong>10% discount:</strong> 4-5 Users </li>)
    content.push(<li key="15"><strong>15% discount:</strong> 6-9 Users </li>)
    return content;
  }

  const getProducts = (type) => {
    const content = [];
    const target_products = products.filter(el => el.plan_type.includes(type));
    target_products.forEach(product => {
      content.push(<li key={product.plan_type}> {product.name} - <strong>{getPrice(product.plan_type, 'year', true)}</strong> or <strong>{getPrice(product.plan_type, 'month', true)}</strong></li>)
    });

    return content;
  }

  const account_types = [
    { id: 'standard-sub', primary: 'Individual User Subscription', secondary: '', modal_title: 'Individual User Subscription', modal_body: () => {
      return (
        <Typography gutterBottom>
          Full access to VetCompanion’s cutting edge clinical information and respected drug formulary content. <br /> <br />
          The subscription is <strong>{getPrice('standard', 'year')}</strong> or <strong>{getPrice('standard', 'month')}</strong> which renews automatically.  You may cancel an annual subscription within 30 days for a full refund. Monthly subscriptions may be cancelled at any time, with payments ending the following month.
        </Typography>
      )
    }},
    { id: 'vma-sub', primary: 'Individual VMA Member Subscription*', secondary: '*Please see details for eligibility information', modal_title: 'Individual VMA Member Subscription', modal_body: () => {
      return (
        <>
          <Typography gutterBottom>
            If you are a member of the Veterinary Medical Association in {assocStates.map((el, i) => i == assocStates.length - 1 ? 'or ' + `${el.name}, ` : '' + `${el.name}, `)} you are eligible to receive a discount on VetCompanion! 
            <br /><br />
            If you are a new member of your VMA, you will receive a 30% discount for the first year and 20% discount the following years.  Existing VMA members receive a 20% discount.
          </Typography>
          <ul className={classes.discountList}>
            { getProducts('vma') }
          </ul>
        </>
      )
    }},
    { id: 'multi-sub', primary: 'Multi-User subscription', secondary: '', modal_title: 'Multi-User Subscription', modal_body: () => {
      return (
        <>
          <Typography >
            If you would like to provide subscriptions to VetCompanion for multiple users, we offer the following discounts:
          </Typography>
          <ul className={classes.discountList}>
            { getBulkDiscountList() }
          </ul>
        </>
      )
    }},
    { id: 'rg-sub', primary: 'Recent Graduate Subscription', secondary: '', modal_title: 'Recent Graduate Subscription', modal_body: () => {
      return (
        <>
          <Typography gutterBottom>
            If you have recently graduated from veterinary school or veterinary technician/nursing school, you are eligible for the following Recent Graduate discounts:
          </Typography>
          <ul className={classes.discountList}>
            <li><strong>{getPrice('rg_1', 'month')}</strong> or <strong>{getPrice('rg_1', 'year')}:</strong> 1st year post-graduation</li>
            <li><strong>{getPrice('rg_2', 'month')}</strong> or <strong>{getPrice('rg_2', 'year')}:</strong> 2nd year post-graduation</li>
            <li><strong>{getPrice('rg_3', 'month')}</strong> or <strong>{getPrice('rg_3', 'year')}:</strong> 3rd year post-graduation</li>
          </ul>
        </>
      )
    }},
    { id: 'student-sub', primary: 'Student, Intern, or Resident Free Trial', secondary: '', modal_title: 'Student, Intern, or Resident Free Trial', modal_body: () => {
      return (
        <Typography gutterBottom>
          Enjoy free trial access with an official email account from your veterinary school or veterinary technician/nursing school (until graduation).<br /> <br />
          Your free trial subscription will continue until August of your graduation year. Then, recent graduates will automatically receive a significant subscription discount. Interns and residents may <a href="/contact">contact us</a> to extend their free trial until they complete their internship and/or residency program. <br /><br />
          Currently, VetCompanion formulary content access is limited for free trial users, due to licensing restrictions. However, we do offer the option to pay a minimal fee of <strong>{getPrice('formulary', 'month')}</strong> to activate full formulary access.
        </Typography>
      )
    }},
  ];

  const handleToggle = (value) => () => {
    const newChecked = [value];
    actions.step1FieldChange({id: 'subscription_type', value});
    setChecked(newChecked);
  };

  const handleDialogClose = () => {
    actions.toggleDialog();
  };

  const handleInfoClick = (id) => {
    const account_obj = account_types.find(e => e.id == id);
    actions.setDialoInfo({
      title: account_obj.modal_title,
      body: account_obj.modal_body
    });
    actions.toggleDialog();
  }

  const handleNextClick = () => {
    actions.setStep(2);
    history.push('/2')
  }

  if (debug) console.log('SubscriptionType RENDER');

  return (
    <>
      <List className={classes.root}>
        {account_types.map((acc_obj) => {
          const { id, primary, secondary} = acc_obj;
          const labelId = `checkbox-list-label-${id}`;

          return (
            <React.Fragment key={id}>
              <ListItem role={undefined} dense button onClick={handleToggle(id)}>
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={checked.indexOf(id) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={primary} secondary={secondary} />
                <ListItemSecondaryAction>
                  <IconButton edge="end" aria-label="help" onClick={() => { handleInfoClick(id) }}>
                    <HelpIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
              {/* { valid 
                ? <a onClick={ functions.locationReset } className={classes.resetButton}>reset</a>
                : null
              } */}
              <Divider />
            </React.Fragment>
          );
        })}
      </List>
      <div className={classes.buttonContainer}>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={handleNextClick}
          disabled={valid ? false : true}
        >
          Next
        </Button>
      </div>
      <CustomDialog actions={actions} dialogData={dialogData} handleClose={handleDialogClose} />
    </>
  );
}

SubscriptionType.propTypes = {
  actions: PropTypes.object.isRequired,
  dialogData: PropTypes.object.isRequired,
  subscription_type: PropTypes.object.isRequired,
  valid: PropTypes.bool.isRequired,
};

export default SubscriptionType;