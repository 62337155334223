
import { call, takeLatest, select } from 'redux-saga/effects';
import callApi from './saga_helpers.js';

import {SAVE_PASSWORD, CONFIRM_PASSWORD} from 'components/SavePassword/constants';

import { REQUEST_FAIL } from 'components/Shared/constants';

const API_URL = process.env.API_URL;
import url from 'url';

export function* savePassword() {
  let debug = false;
  const state = yield select();
  const queryURL = url.resolve(API_URL, `/users/confirm`);
  const token = document.querySelector('[name=csrf-token]').content;
  const password = state.passwordFormReducer.password;
  const password_confirmation = state.passwordFormReducer.password_confirmation;
  const confirmation_token = state.passwordFormReducer.token;

  if (debug) console.log('sagas - getInvoice');

  const opts = {
    method: 'POST',
    cache: 'no-cache',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': token,
    },
    body: JSON.stringify({
      password,
      password_confirmation,
      confirmation_token,
    })
  };

  if (debug) console.log(opts);

  try {
    const payload = yield call(callApi, queryURL, opts, REQUEST_FAIL);

    if (debug) console.log(payload);

    if (payload) {
      // yield put({type: SAVE_PASSWORD_SUCCESS, payload});
      window.location = '/monographs';
    }
    
    return payload;

  } catch(error) {
    if (debug) console.log(error);
  }
}

export function* confirmPassword() {
  let debug = false;
  const state = yield select();
  const queryURL = url.resolve(API_URL, `/users/confirm_email`);
  const token = document.querySelector('[name=csrf-token]').content;
  const password = state.passwordFormReducer.password;
  const confirmation_token = state.passwordFormReducer.token;

  if (debug) console.log('sagas - getInvoice');

  const opts = {
    method: 'POST',
    cache: 'no-cache',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': token,
    },
    body: JSON.stringify({
      password,
      confirmation_token,
    })
  };

  if (debug) console.log(opts);

  try {
    const payload = yield call(callApi, queryURL, opts, REQUEST_FAIL);

    if (debug) console.log(payload);

    if (payload) {
      window.location = '/redirect?message=Email Updated Successfully. Please use this email to login.';
    }
    
    return payload;

  } catch(error) {
    if (debug) console.log(error);
  }
}

export const PasswordSagas = [
  takeLatest(SAVE_PASSWORD, savePassword),
  takeLatest(CONFIRM_PASSWORD, confirmPassword),
]