import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import App from './App';

import * as actions from './actions';
let debug = false;

function mapStateToProps(state) {
  if (debug) console.log('App - mapStateToProps');
  return {
    // globalData: state.globalReducer,
  };
}

function mapDispatchToProps(dispatch) {
  if (debug) console.log('App - mapDispatchToProps');
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);