import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import InputText from 'components/Forms/Fields/InputText';
import InputSelect from 'components/Forms/Fields/InputSelect';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import { getFieldsets } from 'helpers/formFields';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  buttonContainer: theme.buttonContainer,
  centerButton: {
    justifyContent: 'space-around',
  },
  divider: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(.5),
  },
  buttonProgress: theme.buttonProgress,
  root: theme.defaultPaper,
  textField: {
    backgroundColor: 'white',
    width: '100%',
  },
  requiredText: {
    fontSize: '18px',
    marginTop: '12px'
  }
}));

function AccountInfo(props) {
  let debug = false;
  if (debug) console.log(props);

  const { submitting, setSubmitting, actions, activeStep, assocStates, history, requiredFields: savedRequiredFields, scroller, subscriptionType, valid, states } = props;

  const classes = useStyles();

  let fieldsets = [];
  fieldsets = getFieldsets(subscriptionType, assocStates, states);

  // generate the input/select field content in the 'fieldset'
  const fieldContent = (field) => {
    const { id, input } = field;
    let field_tag;

    const field_props = {
      disabled: false,
      ...field,
      subscriptionType,
      savedValue: props[id] ? props[id].value || '' : '',
      error: props[id] ? props[id].error : false,
      errorMessage: props[id] ? props[id].errorMessage : '',
      changeAction: actions.accountFieldChange,
      emailCheckAction: actions.checkStudentEmail,
    }
    
    if (input === 'text') {
      field_tag = <InputText { ...field_props } />
    } else if (input === 'select') {
      field_tag = <InputSelect { ...field_props } />
    }

    return field_tag;
  }
  // keep track of fields that are required in redux to check later
  const required_fields = [];
  // generate fieldset content, which is just nested Grids
  const fieldsetContent = (fieldset) => {
    const content = [];
    const fields = fieldset.fields;
    fields.forEach((field, index) => {
      const field_content = <Grid item key={`field-${index}`} lg={fieldset.options.lg[index]} xs={fieldset.options.xs[index]}>
        { fieldContent(field) }
      </Grid>
      if (field.required) required_fields.push(field.id);
      content.push(field_content);
    });
    return content;
  }
  // iterate over the fieldsets data and render either a container Grid or a Divider
  const formContent = () => {
    const content = [];

    fieldsets.every((fieldset, index) => {
      if (fieldset.options.notIf) {
        if (props[fieldset.options.notIf.field]) {
          if (props[fieldset.options.notIf.field]['value'] === fieldset.options.notIf.value) {
            return true;
          }
        }
      }

      // const savedValued = props[fieldset.options.dependsOn.field] ? props[fieldset.options.dependsOn.field]['value'] : '';
      if (fieldset.options.dependsOn) {
        if (props[fieldset.options.dependsOn.field]) {
          if (props[fieldset.options.dependsOn.field]['value'] !== fieldset.options.dependsOn.value) {
            return true;
          }
        } else {
          return true
        }
      }

      let fieldset_content;
      if (fieldset.options.type === 'divider') {
        fieldset_content = <Divider className={classes.divider} key={`fieldset-${index}`} />
      } else {
        fieldset_content = <Grid container key={`fieldset-${index}`} spacing={1}>
          { fieldsetContent(fieldset) }
        </Grid>
      }
      content.push(fieldset_content);

      return true
    });

    return content;
  }

  const handleBackClick = () => {
    scroller.scrollTo("scrollPoint", { duration: 500, smooth: true});
    setTimeout(() => {
      actions.setStep(1);
      actions.removeErrors();
    }, 500);
  }

  const handleNextClick = () => {
    scroller.scrollTo("scrollPoint", { duration: 500, smooth: true});
    setTimeout(() => {
      actions.setStep(3);
      history.push('/3');
    }, 500);
  }
  
  const handleAccountUpdate = () => {
    if (setSubmitting) {
      setSubmitting(true);
    }
    actions.saveAccountInfo();
  }

  const renderBackButton = () => {
    if (subscriptionType !== 'my-account') {
      return (
        <Button
          className={classes.button}
          onClick={handleBackClick}
        >
          Back
        </Button>
      )
    }
  }

  const renderNextButton = () => {
    if (subscriptionType !== 'my-account') {
      return (
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={handleNextClick}
          disabled={valid ? false : true}
        >
          Next
        </Button>
      )
    } else {
      return (
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={handleAccountUpdate}
          disabled={valid ? submitting || false : true}
        >
          Update
          { submitting && <CircularProgress size={24} className={classes.buttonProgress} /> }
        </Button>
      )
    }
  }
  
  const renderButtons = () => {
    let classNames = `${classes.buttonContainer} `
    if (subscriptionType === 'my-account') {
      classNames+= `${classes.centerButton}`
    }
    return (
      <div className={classNames}>
        { renderBackButton() }
        { renderNextButton() }
      </div>
    )
  }

  if (debug) console.log('AccountInfo RENDER');

  useEffect(() => {
    if (activeStep < 2) {
      history.replace('/1')
    }
    if (valid && savedRequiredFields.length !== required_fields.length) {
      actions.setAccountRequired(required_fields);
    }
  });
  
  return (
    <>
      { formContent() }
      <div className={classes.requiredText}>* Required Fields</div>
      { renderButtons() }
    </>
  );
}

AccountInfo.propTypes = {
  actions: PropTypes.object.isRequired,
  activeStep: PropTypes.number.isRequired,
  assocStates: PropTypes.array,
  requiredFields: PropTypes.array,
  subscriptionType: PropTypes.string.isRequired,
  valid: PropTypes.bool.isRequired,
};

export default AccountInfo;