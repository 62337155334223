import * as types from './constants';
import * as savePassTypes from 'components/SavePassword/constants';
import * as myAccountTypes from 'components/MyAccount/Info/constants';
import * as signUpTypes from 'components/SignUp/constants';

let debug = false;

export function bootstrapCoupons(data) {
  if (debug) console.log("App ACTIONS - bootstrapCoupons");
  return {
    type: signUpTypes.BOOTSTRAP_COUPONS,
    data
  };
}

export function bootstrapUser(data) {
  if (debug) console.log("App ACTIONS - bootstrapUser");
  return {
    type: myAccountTypes.BOOTSTRAP_USER,
    data
  };
}

export function bootstrapSubscription(data) {
  if (debug) console.log("App ACTIONS - bootstrapSubscription");
  return {
    type: myAccountTypes.BOOTSTRAP_SUBSCRIPTION,
    data
  };
}

export function bootstrapDefaultStep(data) {
  if (debug) console.log("App ACTIONS - loadAssobootstrapDefaultStepcStates");
  return {
    type: signUpTypes.SET_STEP,
    data
  };
}

export function bootstrapDefaultSub(data) {
  if (debug) console.log("App ACTIONS - bootstrapDefaultSub");
  return {
    type: signUpTypes.BOOTSTRAP_DEFAULT_SUBSCRIPTION,
    data
  };
}

export function bootstrapToken(data) {
  if (debug) console.log("App ACTIONS - bootstrapToken");
  return {
    type: savePassTypes.BOOTSTRAP_TOKEN,
    data
  };
}

export function bootstrapPersonalMessage(data) {
  if (debug) console.log("App ACTIONS - bootstrapPersonalMessage");
  return {
    type: myAccountTypes.BOOTSTRAP_PERSONAL_MESSAGE,
    data
  };
}

// export function loadUserCoupon(data) {
//   if (debug) console.log("App ACTIONS - loadUserCoupon");
//   return {
//     type: signUpTypes.LOAD_USER_COUPON,
//     data
//   };
// }


export function loadAssocStates(data) {
  if (debug) console.log("App ACTIONS - loadAssocStates");
  return {
    type: types.BOOTSTRAP_ASSOC_STATES,
    data
  };
}

export function loadProducts(data) {
  if (debug) console.log("App ACTIONS - loadProducts");
  return {
    type: signUpTypes.BOOTSTRAP_PRODUCTS,
    data
  };
}

export function loadSection(data) {
  if (debug) console.log("App ACTIONS - loadSection");
  return {
    type: types.BOOTSTRAP_SECTION,
    data
  };
}

export function bootstrapPhoneInstructions(data) {
  if (debug) console.log("App ACTIONS - bootstrapPhoneInstructions");
  return {
    type: myAccountTypes.BOOTSTRAP_PHONE_INSTRUCTIONS,
    data
  };
}
// for settings "add a subscription"
export function loadSubscriptionAccountInfo(data) {
  if (debug) console.log("App ACTIONS - bootstrapPhoneInstructions");
  return {
    type: signUpTypes.LOAD_SUBSCRIPTION_ACCOUNTINFO,
    data
  };
}

export function bootstrapSubscriptionExpired(data) {
  if (debug) console.log("App ACTIONS - bootstrapSubscriptionExpired");
  return {
    type: types.BOOTSTRAP_SUBSCRIPTION_EXPIRED,
    data
  };
}

export function bootstrapIncompleteSettings(data) {
  if (debug) console.log("App ACTIONS - bootstrapIncompleteSettings");
  return {
    type: types.BOOTSTRAP_INCOMPLETE_SETTINGS,
    data
  };
}

export function bootstrapFutureSubscription(data) {
  if (debug) console.log("App ACTIONS - bootstrapFutureSubscription");
  return {
    type: types.BOOTSTRAP_FUTURE_SUBSCRIPTION,
    data
  };
}

export function bootstrapStates(data) {
  if (debug) console.log("App ACTIONS - bootstrapStates");
  return {
    type: types.BOOTSTRAP_STATES,
    data
  };
}