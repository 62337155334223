import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import InputText from 'components/Forms/Fields/InputText';
import Button from '@material-ui/core/Button';

import CustomDialog from 'components/CustomDialog';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  root: theme.buttonContainer,
  container: {
    margin: '50px auto 100px auto',
    padding: theme.spacing(1),
  },
  buttonContainer: {
    ...theme.buttonContainer,
    justifyContent: 'space-around',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  buttonProgress: theme.buttonProgress,
  subtitle: theme.subtitle,
  subtitle2: theme.subtitle2,
  errorList: theme.errorList
}));

function SavePassword(props) {
  let debug = false;
  const classes = useStyles();
  const [showingErrors, setShowingErrors] = useState(false);

  if (debug) console.log(props);

  const { passwordData, dialogData, actions, sharedActions, errors } = props;
  const { submitting, password, password_confirmation, valid } = passwordData;

  const handleFieldChange = ({ key, value }) => {
    actions.fieldChange({ key, value })
  }

  const handleSubmitClick = () => {
    actions.savePassword()
  }

  const handleDialogClose = () => {
    if (errors.length > 0) {
      sharedActions.resetErrors();
      setShowingErrors(false);
    }
    sharedActions.toggleDialog();
  };

  const setErrorDialogInfo = () => {
    sharedActions.setDialoInfo({
      title: 'Errors with submission',
      body: () => {
        const renderErrors = () => {
          const errorsHtml = [];
          errors.forEach((error, index) => {
            const errorHtml = <li key={`error-${index}`}>{error.message}</li>
            errorsHtml.push(errorHtml);
          });
          return errorsHtml;
        }
        return (
          <ul className={classes.errorList}>
            {renderErrors()}
          </ul>
        )
      }
    });
  }

  const passwordProps = {
    disabled: false,
    changeAction: handleFieldChange,
    description: 'minimum 8 characters',
    label: 'Password',
    id: 'password',
    savedValue: password || '',
    type: 'password',
  }

  const passwordConfirmProps = {
    disabled: false,
    changeAction: handleFieldChange,
    description: 'must match password',
    label: 'Confirm Password',
    id: 'password_confirmation',
    savedValue: password_confirmation || '',
    type: 'password',
  }

  useEffect(() => {
    if (!showingErrors && errors.length > 0) {
      setErrorDialogInfo();
      sharedActions.toggleDialog();
      setShowingErrors(true);
    }
  });

  if (debug) console.log('SavePassword RENDER');

  return (
    <Container maxWidth="sm" align="center" className={classes.container}>
      <Paper>
        <Typography variant='subtitle1' align="center" className={classes.subtitle}>
          Set Your Password
        </Typography>

        <InputText { ...passwordProps } />
        <InputText { ...passwordConfirmProps } />

        <div className={classes.buttonContainer}>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={handleSubmitClick}
            disabled={valid ? submitting || false : true}
          >
            Submit
            { submitting && <CircularProgress size={24} className={classes.buttonProgress} /> }
          </Button>
        </div>
      </Paper>
      <CustomDialog dialogData={dialogData} handleClose={handleDialogClose} />
    </Container>
  );
}

SavePassword.propTypes = {
  passwordData: PropTypes.object,
  dialogData: PropTypes.object,
  actions: PropTypes.object,
};

export default SavePassword;