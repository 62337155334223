import * as types from './constants';

let debug = false;

export function confirmPassword() {
  if (debug) console.log("SavePassword ACTIONS - savePassword");
  return {
    type: types.CONFIRM_PASSWORD,
  };
}

export function savePassword() {
  if (debug) console.log("SavePassword ACTIONS - savePassword");
  return {
    type: types.SAVE_PASSWORD,
  };
}
  
export function fieldChange(data) {
  if (debug) console.log("SavePassword ACTIONS - fieldChange");
  return {
    type: types.FIELD_CHANGE,
    data
  };
}