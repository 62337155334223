import * as types from './constants';

let debug = false;

export function accountFieldChange(data) {
  if (debug) console.log("MyAccount - accountFieldChange");
  if (debug) console.log(data);
  return {
    type: types.MYACCOUNT_FIELD_CHANGE,
    data
  };
}

export function setAccountRequired(data) {
  if (debug) console.log("MyAccount - setAccountRequired");
  return {
    type: types.SET_ACCOUNT_REQUIRED,
    data
  };
}

export function saveAccountInfo(data) {
  if (debug) console.log("MyAccount - setAccountRequired");
  return {
    type: types.SAVE_ACCOUNT_INFO,
    data
  };
}

export function cancelSubscription(data) {
  if (debug) console.log("MyAccount - cancelSubscription");
  return {
    type: types.CANCEL_SUBSCRIPTION,
    data
  };
}

export function cancelSubscriptionToggle(data) {
  if (debug) console.log("MyAccount - cancelSubscriptionToggle");
  return {
    type: types.CANCEL_SUBSCRIPTION_TOGGLE,
    data
  };
}
