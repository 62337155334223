import standardFormData from 'data/standardSignup';
import studentFormData from 'data/studentSignup';
import vmaSignup from 'data/vmaSignup';
import rgSignup from 'data/rgSignup';
import myAccount from 'data/myAccount';

const getFieldsets = (subscriptionType, assocStates, states) => {
  let fieldsets = [];
  // get the right form data for each subscriptionType
  switch (subscriptionType) {
    case 'student-sub':
      fieldsets = studentFormData(states);
      break;
    case 'vma-sub':
      fieldsets = vmaSignup(states, assocStates);
      break;
    case 'rg-sub':
      fieldsets = rgSignup(states);
      break;
    case 'my-account':
      fieldsets = myAccount(states);
      break;
    default:
      fieldsets = standardFormData(states);
      break;
  }
  return fieldsets;
}

const getFieldNames = (subscriptionType) => {
  const fieldsets = getFieldsets(subscriptionType, [], []);
  const fieldNames = fieldsets.reduce((acc, cv) => {
    if (cv.fields) {
      cv.fields.forEach(el => acc.push(el.id))
    };
    return acc;
  }, []);
  return fieldNames;
}

export {
  getFieldsets,
  getFieldNames
}