
import { call, takeLatest, select, put } from 'redux-saga/effects';
import callApi from './saga_helpers.js';

import {GET_INVOICE_SUCCESS, GET_INVOICE, GET_INVOICE_BY_ID, STUDENT_EMAIL_CHECK, STUDENT_EMAIL_CHECK_SUCCESS, STUDENT_EMAIL_CHECK_FAIL } from 'components/SignUp/constants';

import { GET_INTENT_SECRET, GET_INTENT_SECRET_SUCCESS, SUBSCRIPTION_SEND_ERROR, SUBSCRIPTION_SEND, SUBSCRIPTION_SEND_SUCCESS, REQUEST_FAIL, GET_PROMO_CODE, GET_PROMO_CODE_SUCCESS, GET_PROMO_CODE_FAIL } from 'components/Shared/constants';

import { getFieldNames } from 'helpers/formFields';

const API_URL = process.env.API_URL;
import url from 'url';
// import eventPayloadStructure from './structure/Event.json'

export function* submitSubscription(action) {
  let debug = false;
  const state = yield select();
  let userId;

  const paymentInfo = state.signupFormReducer.paymentInfo;
  const accountInfo = state.signupFormReducer.accountInfo;
  const subType = state.signupFormReducer.subscriptionType.subscription_type.value;
  const section = state.globalReducer.section;
  const accountKeys = getFieldNames(subType);

  if (debug) console.log('sagas - submitSubscription');

  const { plans, billingInterval, totals } = paymentInfo;

  const items = plans.reduce((acc, cv) => {
    if (cv.quantity > 0) {
      const prices = cv.stripe_product.prices;
      const item = {};
      const price = prices.length === 1 ? prices[0] : prices.find(el => el.interval === billingInterval);
      item['price'] = price.stripe_price_id;
      item['quantity'] = cv.quantity;
      acc.push(item);
    }
    return acc;
  }, []);

  const subcriptionCoupon = totals.couponsInfo.find(el => el.coupon_type === 'subscription' || el.coupon_type === 'admin');
  const customerCoupon = totals.couponsInfo.find(el => el.coupon_type === 'customer');
  const token = document.querySelector('[name=csrf-token]').content;

  let queryURL;
  if (section === 'admin') {
    userId = state.myAccountFormReducer.user.id.value;
    queryURL = url.resolve(API_URL, `admin/users/${userId}/subscription`);
  } else if (section === 'add_subscription') {
    queryURL = url.resolve(API_URL, `/settings/add_subscription`);
  } else {
    queryURL = url.resolve(API_URL, `/users`);
  }

  const usersBody = {};
  accountKeys.forEach(key => {
    if (key !== 'valid' && key !== 'requiredFields' && key !== 'completedRequired') {
      if (accountInfo[key]) {
        if (key === 'degree_earned_other' && accountInfo['degree_earned_other']['value']) {
          usersBody['degree_earned'] = accountInfo['degree_earned_other']['value'];
        } else {
          usersBody[key] = accountInfo[key]['value'];
        }
      }
    }
  });

  if (subType === 'student-sub') {
    usersBody['is_student'] = true;
    const products = state.signupFormReducer.products;
    const formulary_id = products.find(element => element.plan_type === 'formulary').prices[0].stripe_price_id;
    const formulary_found = items.find(element => element.price === formulary_id);
    if (formulary_found) {
      usersBody['subscription_type'] = 'student_formulary';
    } else {
      usersBody['subscription_type'] = 'student';
    }
  } else if (subType === 'rg-sub') {
    const rg_type = plans[0].plan_type;
    switch (rg_type) {
      case 'rg_1':
        usersBody['subscription_type'] = 'recent_graduate_1';
        break;
      case 'rg_2':
        usersBody['subscription_type'] = 'recent_graduate_2';
        break;
      case 'rg_3':
        usersBody['subscription_type'] = 'recent_graduate_3';
        break;
      default:
        usersBody['subscription_type'] = 'recent_graduate_3';
        break;
    }
  } else if (subType === 'multi-sub') {
    usersBody['subscription_type'] = 'multi';
  } else if (subType === 'vma-sub') {
    const vma_type = plans[0].stripe_product.plan_type;
    if (vma_type === 'new_vma') {
      usersBody['subscription_type'] = 'new_vma';
    } else {
      usersBody['subscription_type'] = 'vma';
    }
  } else {
    usersBody['subscription_type'] = 'single';
  }

  const opts = {
    method: 'POST',
    // mode: 'cors',
    // credentials: 'same-origin',
    cache: 'no-cache',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': token,
    },
    body: JSON.stringify({
      subscription_data: {
        collection_method: action.data.collection_method,
        subcription_coupon: subcriptionCoupon ? subcriptionCoupon.coupon_id : null,
        customer_coupon: customerCoupon ? customerCoupon.coupon_id : null,
        payment_method_id: action.data.payment_method_id || null,
        items,
      },
      user: { 
        ...usersBody,
      }
    })
  };

  if (debug) console.log(opts);
  
  try {
    const payload = yield call(callApi, queryURL, opts, SUBSCRIPTION_SEND_ERROR);

    if (debug) console.log(payload);

    if (payload) {
      if (subType === 'admin') {
        setTimeout(() => {
          window.scrollTo(0,0);
          window.location.reload();
        }, 4000);
      } else {
        yield put({type: SUBSCRIPTION_SEND_SUCCESS, payload});
      }
    }
    
    return payload;

  } catch(error) {
    if (debug) console.log(error);
  }
}

export function* getIntentSecret() {
  let debug = false;
  let userId;
  const state = yield select();
  const subType = state.signupFormReducer.subscriptionType.subscription_type.value;

  if (subType === 'admin') {
    userId = state.myAccountFormReducer.user.id.value;
  }

  let queryURL;
  if (subType === 'admin') {
    queryURL = url.resolve(API_URL, `admin/users/${userId}/get_intent`);
  } else {
    queryURL = url.resolve(API_URL, `/users/get_intent`);
  }

  const token = document.querySelector('[name=csrf-token]').content;

  if (debug) console.log('sagas - getIntentSecret');

  const opts = {
    method: 'POST',
    cache: 'no-cache',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': token,
    },
    body: JSON.stringify({})
  };

  if (debug) console.log(opts);

  try {
    const payload = yield call(callApi, queryURL, opts, SUBSCRIPTION_SEND_ERROR);

    if (debug) console.log(payload);

    if (payload) {
      yield put({type: GET_INTENT_SECRET_SUCCESS, payload});
    }
    
    return payload;

  } catch(error) {
    if (debug) console.log(error);
  }
}

export function* getInvoice() {
  let debug = false;
  const state = yield select();  
  const token = document.querySelector('[name=csrf-token]').content;
  const section = state.globalReducer.section;
  
  const userId = state.signupFormReducer.paymentInfo.userId;

  let queryURL;
  if (section === 'add_subscription') {
    queryURL = url.resolve(API_URL, `/settings/get_invoice`);
  } else {
    queryURL = url.resolve(API_URL, `/users/get_invoice`);
  }

  if (debug) console.log('sagas - getInvoice');

  const opts = {
    method: 'POST',
    cache: 'no-cache',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': token,
    },
    body: JSON.stringify({
      user_id: userId
    })
  };

  if (debug) console.log(opts);

  try {
    const payload = yield call(callApi, queryURL, opts, REQUEST_FAIL);

    if (debug) console.log(payload);

    if (payload) {
      yield put({type: GET_INVOICE_SUCCESS, payload});
    }
    
    return payload;

  } catch(error) {
    if (debug) console.log(error);
  }
}

export function* getPromo() {
  let debug = false;
  const state = yield select();
  const token = document.querySelector('[name=csrf-token]').content;
  
  const promoCode = state.signupFormReducer.paymentInfo.promoCode;
  const plans = state.signupFormReducer.paymentInfo.plans;
  const interval = state.signupFormReducer.paymentInfo.billingInterval;
  const planIds = plans.reduce((acc, cv) => { acc.push(cv.stripe_product.stripe_product_id); return acc; }, []).join(',');

  const queryURL = url.resolve(API_URL, `/users/get_promo`);

  if (debug) console.log('sagas - getPromo');

  const opts = {
    method: 'POST',
    cache: 'no-cache',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': token,
    },
    body: JSON.stringify({
      code: promoCode,
      interval,
      planIds
    })
  };

  if (debug) console.log(opts);
  
  try {
    const payload = yield call(callApi, queryURL, opts, GET_PROMO_CODE_FAIL);

    if (debug) console.log(payload);

    if (payload) {
      yield put({type: GET_PROMO_CODE_SUCCESS, payload});
    }
    
    return payload;

  } catch(error) {
    if (debug) console.log(error);
  }
}

export function* getInvoiceById(action) {
  let debug = true;
  const queryURL = url.resolve(API_URL, `/users/get_invoice`);
  const token = document.querySelector('[name=csrf-token]').content;

  if (debug) console.log('sagas - getInvoice');

  const opts = {
    method: 'POST',
    cache: 'no-cache',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': token,
    },
    body: JSON.stringify({
      invoice_id: action.data
    })
  };

  if (debug) console.log(opts);

  try {
    const payload = yield call(callApi, queryURL, opts, REQUEST_FAIL);

    if (debug) console.log(payload);

    if (payload) {
      yield put({type: GET_INVOICE_SUCCESS, payload});
    }
    
    return payload;

  } catch(error) {
    if (debug) console.log(error);
  }
}

export function* checkStudentEmail(action) {
  let debug = false;
  const queryURL = url.resolve(API_URL, `/users/student_email_check`);
  const token = document.querySelector('[name=csrf-token]').content;

  if (debug) console.log('sagas - checkStudentEmail');

  const opts = {
    method: 'POST',
    cache: 'no-cache',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': token,
    },
    body: JSON.stringify({
      email: action.data
    })
  };

  if (debug) console.log(opts);

  try {
    const payload = yield call(callApi, queryURL, opts, STUDENT_EMAIL_CHECK_FAIL);

    if (debug) console.log(payload);

    if (payload) {
      yield put({type: STUDENT_EMAIL_CHECK_SUCCESS, payload});
    }
    
    return payload;

  } catch(error) {
    if (debug) console.log(error);
  }
}

export const SignUpSagas = [
  takeLatest(SUBSCRIPTION_SEND, submitSubscription),
  takeLatest(GET_INTENT_SECRET, getIntentSecret),
  takeLatest(GET_INVOICE, getInvoice),
  takeLatest(GET_PROMO_CODE, getPromo),
  takeLatest(GET_INVOICE_BY_ID, getInvoiceById),
  takeLatest(STUDENT_EMAIL_CHECK, checkStudentEmail),
]