import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    textAlign: 'center',
  },
  header: {
    background: "-webkit-linear-gradient(-90deg, #ff9319 20%, #fed700 100%)",
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
    paddingBottom: theme.spacing()
  },
  headline: {
    display: 'inline-block',
    color: '#060e9f',
    lineHeight: '34px',
  },
  instructions: {
    paddingBottom: theme.spacing()
  },
  custom: theme.customHr
}));

function Header(props) {
  let debug = false;
  const classes = useStyles();

  if (debug) console.log(props);

  const { title } = props;

  if (debug) console.log('Header RENDER');

  return (
    <div className={classes.headerContainer}>
      <Typography className={classes.headline} variant="h4">
        <span className="vc_header_logo pull-left"></span> {title}
      </Typography>
      <hr className={classes.custom} />
    </div>
  );
}

Header.propTypes = {};

export default Header;