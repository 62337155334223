export default {
  global: {
    dialog: {
      show: false,
      info: {}
    },
    errors: [],
    states: [],
  },
  forms: {
    signup: {
      coupons: [],
      coupon: false,
      couponProducts: [],
      productTypes: [],
      previous_products: [],
      progress: {
        step: 1,
        submitting: false,
      },
      subscriptionType: {
        valid: false,
        subscription_type: {
          value: '',
          error: false,
          errorMessage: ''
        }
      },
      accountInfo: {
        valid: false,
        requiredFields: [],
        completedRequired: [],
        country: {
          value: "",
          error: false,
          errorMessage: ""
        }
      },
      paymentInfo: {
        agreed: false,
        done: false,
        valid: false,
        submitting: false,
        stripeValid: false,
        gettingPaymentMethod: false,
        adminCoupons: [],
        adminSendInvoiceValid: false,
        billingInterval: 'year',
        billingIntervalDisabled: false,
        requiredFields: [],
        completedRequired: [],
        customerCoupons: [],
        subscriptionCoupons: [],
        addGraduateYear: '',
        addVMAState: '',
        addVMAYear: '',
        promoCode: '',
        promoError: '',
        promoSuccess: false,
        tooManyUsers: false,
        plans: [],
        errors: [],
        totals: {
          subtotal: 0,
          couponsInfo: [],
          total: 0,
        }
      },
      finishScreen: {
        invoice: {},
      }
    },
    password: {
      password: '',
      password_confirmation: '',
      submitting: false,
      valid: false,
      confirmation_token: '',
    },
    myaccount: {
      valid: false,
      requiredFields: [],
      completedRequired: [],
      personalMessage: '',
      phoneInstructions: {},
      subscriptionCancelled: false,
      subscriptionRefunded: false,
    }
  }
};
