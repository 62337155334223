import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';

import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import configureStore, { history } from 'store/configureStore';
import App from 'containers/App';

import theme from 'styles/theme';

const store = configureStore();
let debug = false;

function Root(props) {
  if (debug) console.log(props);

  if (debug) console.log('Root RENDER');

  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <CssBaseline />
        <App {...props} history={history} />
      </Provider>
    </ThemeProvider>
  );
}

Root.propTypes = {
  assoc_states: PropTypes.string,
  confirmation_token: PropTypes.string,
  defaultStep: PropTypes.any,
  interval: PropTypes.any,
  section: PropTypes.string,
  coupons: PropTypes.string,
  products: PropTypes.string,
  personal_message: PropTypes.string,
  phone_instructions: PropTypes.object,
};

export default Root;