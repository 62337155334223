import {
  BOOTSTRAP_ASSOC_STATES,
  BOOTSTRAP_STATES,
  BOOTSTRAP_SECTION,
  BOOTSTRAP_SUBSCRIPTION_EXPIRED,
  BOOTSTRAP_INCOMPLETE_SETTINGS,
  BOOTSTRAP_FUTURE_SUBSCRIPTION,
} from 'containers/App/constants';

import {
  TOGGLE_DIALOG,
  SET_DIALOG_INFO,
  RESET_ERRORS,
  REQUEST_FAIL,
} from 'components/Shared/constants';


// import objectAssign from 'object-assign';
import initialState from './initialState';

let debug = false;

export default function globalReducer(state = initialState.global, action) {
  let data, newDialogData, newState, errors, errorText;
  // let error;

  switch (action.type) {
    case BOOTSTRAP_ASSOC_STATES:
      data = action.data
      return {
        ...state,
        assocStates: JSON.parse(data)
      };

    case BOOTSTRAP_STATES:
      data = action.data
      return {
        ...state,
        states: JSON.parse(data)
      };

    case BOOTSTRAP_SECTION:
      data = action.data
      return {
        ...state,
        section: data
      };


    case REQUEST_FAIL:
      if (debug) console.log("global Reducer - REQUEST_FAIL");

      data = action.data;

      errors = [];

      if (data.error) {
        errors.push(data.error);
      } else {
        errors = [
          ...data.errors
        ]
      }

      if (debug) console.log(errors);

      newState = {
        ...state,
        errors,
      }
      return newState;

    case RESET_ERRORS:
      newState = {
        ...state,
        errors: []
      }
      return newState;

    case SET_DIALOG_INFO:
      if (debug) console.log("global Reducer - SET_DIALOG_INFO");

      data = action.data;
      newDialogData = {
        ...state.dialog,
        info: data
      }
      return {
        ...state,
        dialog: {
          ...newDialogData
        }
      };

    case TOGGLE_DIALOG:
      if (debug) console.log("global Reducer - TOGGLE_DIALOG");

      newDialogData = {
        ...state.dialog,
        show: !state.dialog.show
      }

      if (debug) console.log(newDialogData);

      return {
        ...state,
        dialog: {
          ...newDialogData
        }
      };

    case BOOTSTRAP_FUTURE_SUBSCRIPTION:

      data = action.data;

      newState = {
        ...state,
        future_subscription: data
      }
      return newState;
    
    case BOOTSTRAP_SUBSCRIPTION_EXPIRED:
      data = action.data;

      errorText = 'Subscription expired';

      if (data === 'not_confirmed') {
        errorText = 'Parent user\'s account is not confirmed'
      }
  
      newState = {
        ...state,
        errors: [{message: errorText}]
      }
      return newState;

    case BOOTSTRAP_INCOMPLETE_SETTINGS:
      data = action.data;

      errorText = 'Please fill out your contact information';
  
      newState = {
        ...state,
        errors: [{message: errorText}]
      }
      return newState;


    default:
      return state;
  }
}
