import initialState from '../initialState';

import {
  BOOTSTRAP_PERSONAL_MESSAGE,
  BOOTSTRAP_PHONE_INSTRUCTIONS,
  BOOTSTRAP_SUBSCRIPTION,
  BOOTSTRAP_USER,
  SET_ACCOUNT_REQUIRED,
  MYACCOUNT_FIELD_CHANGE,
  CANCEL_SUBSCRIPTION_TOGGLE,
} from 'components/MyAccount/Info/constants';

let debug = false;

export default function myAccountFormReducer(state = initialState.forms.myaccount, action) {
  let newState, data, userData, newCompletedRequired, idIndex, newValid, errors;

  switch (action.type) {

    case BOOTSTRAP_PERSONAL_MESSAGE:
      data = action.data
      return {
        ...state,
        personalMessage: data
      };

    case BOOTSTRAP_PHONE_INSTRUCTIONS:
      data = action.data
      return {
        ...state,
        phoneInstructions: data
      };

    case BOOTSTRAP_SUBSCRIPTION:
      data = action.data
      let subscription = data[0]
      let interval = data[1]

      if (interval) subscription.interval = interval

      return {
        ...state,
        subscription
      };

    case BOOTSTRAP_USER:
      data = action.data;
      userData = {};
      let userKeyArray = Object.keys(data);

      userKeyArray.forEach(key => {
        userData[key] = {};
        userData[key]['value'] = data[key];
      });

      return {
        ...state,
        user: userData
      };

    case CANCEL_SUBSCRIPTION_TOGGLE:
      data = action.data;
      
      let { cancelled, refunded } = data;

      return {
        ...state,
        subscriptionCancelled: cancelled,
        subscriptionRefunded: refunded
      };

    case MYACCOUNT_FIELD_CHANGE:
      data = action.data

      let newKeyValue = {};
      newKeyValue[data.key] = {
        value: data.value
      };

      userData = {
        ...state.user,
        ...newKeyValue,
      };

      newCompletedRequired = [
        ...state.completedRequired
      ]
      // check if this field is required
      if (state.requiredFields.includes(data.key)) {
        if (data.value) {
          // check if the id already exists in completed array
          idIndex = newCompletedRequired.indexOf(data.key);
          // if it doesn't exist, add it to the array
          if (idIndex === -1) {
            newCompletedRequired.push(data.key);
          }
        } else {
          // check if the id already exists in completed array
          idIndex = newCompletedRequired.indexOf(data.key);
          // if it exists, remove it from the array since there is no value
          if (idIndex > -1) {
            newCompletedRequired.splice(idIndex, 1);
          }
        }
      }

      newValid = newCompletedRequired.length === state.requiredFields.length ? true : false;

      return {
        ...state,
        user: userData,
        completedRequired: newCompletedRequired,
        valid: newValid
      };


    case SET_ACCOUNT_REQUIRED:
      if (debug) console.log("MyAccount Reducer - SET_ACCOUNT_REQUIRED");

      data = action.data;
      newCompletedRequired = [];

      data.forEach(field_id => {
        if (state.user[field_id] && state.user[field_id].value) {
          newCompletedRequired.push(field_id);
        }
      });

      newState = {
        ...state,
        requiredFields: data,
        completedRequired: newCompletedRequired,
        valid: newCompletedRequired.length === data.length ? true : false
      }
      return newState;

    default:
      return state;
  }
}
