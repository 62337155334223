import React, {useEffect, useState, useMemo} from 'react';
import PropTypes from 'prop-types';

import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';

import {CardElement, Elements, useStripe, useElements } from '@stripe/react-stripe-js';

const useStyles = makeStyles((theme) => ({
  textField: {
    backgroundColor: 'white',
    marginBottom: theme.spacing(1/2),
    width: '100%',
  },
  labelOffset: {
    transform: "translate(50px, 20px) scale(1)",
  },
}));

function MyInputComponent(props) {
  const { component: Component, inputRef, ...other } = props;

  // implement `InputElement` interface
  React.useImperativeHandle(inputRef, () => ({
    focus: () => {},
  }));

  return <Component {...other} />;
}

function CreditCard(props) {
  let debug = false;
  const classes = useStyles();
  const stripe = useStripe();
  const elements = useElements();
  const [allowToGet, setAllowToGet] = React.useState(true);

  if (debug) console.log(props);

  const useOptions = () => {
    const options = useMemo(
      () => ({
        style: {
          base: {
            fontFamily: '"myriad-pro", "Open Sans", sans-serif',
            fontSize: '17.5px',
            fontWeight: 400,
            "::placeholder": {
              color: "rgba(0, 0, 0, 0.65)",
              fontFamily: '"myriad-pro", "Open Sans", sans-serif',
              fontSize: '17.5px',
              fontWeight: 300,
            }
          },
          invalid: {
            color: "#9e2146"
          }
        }
      })
    );
  
    return options;
  };

  const {actions, gettingPaymentMethod, intentClientSecret} = props;

  const handleChange = (ev) => {
    actions.setStripeValid(ev.complete)
    if (ev.complete) {
      actions.checkPaymentInfoValid()
    }
  }

  if (debug) console.log('CreditCard RENDER');

  const getPaymentMethod = async () => {
    if (!stripe || !intentClientSecret) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const result = await stripe.confirmCardSetup(intentClientSecret, {
      payment_method: {
        card: cardElement,
      }
    });

    if (result.error) {
      // Show error to your customer (e.g., insufficient funds)
      actions.subscriptionError({error: result.error})
    } else {
      // The payment has been processed!
      if (result.setupIntent.status === 'succeeded') {
        const payment_method_id = result.setupIntent.payment_method;
        actions.sendSubscription({collection_method: 'charge_automatically', payment_method_id});
      }
    }
  }

  useEffect(() => {

    if (!gettingPaymentMethod) {
      setAllowToGet(true);
    }
  
    if (gettingPaymentMethod && allowToGet) {
      setAllowToGet(false);
      getPaymentMethod();
    }
  });

  return (
    <>
      <Grid container spacing={2}>
        <Grid item lg={12} xs={12}>
          <TextField
            variant="outlined"
            fullWidth
            InputProps={{
              inputComponent: MyInputComponent,
              inputProps: {
                options: useOptions(),
                onChange: (ev) => { handleChange(ev) },
                component: CardElement
              },
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}

CreditCard.propTypes = {
  actions: PropTypes.object.isRequired,
};

export default CreditCard;