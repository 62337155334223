import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';

import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import Link from '@material-ui/core/Link';

import { makeStyles } from '@material-ui/core/styles';

import CustomDialog from 'components/CustomDialog';
import BillingCycle from 'components/BillingCycle';
import CreditCard from 'components/CreditCard';
import SubscriptionInvoice from 'components/SubscriptionInvoice';

import CircularProgress from '@material-ui/core/CircularProgress';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const useStyles = makeStyles((theme) => ({
  root: theme.defaultPaper,
  buttonContainer: theme.buttonContainer,
  subtitle: theme.subtitle,
  subtitle2: theme.subtitle2,
  buttonProgress: theme.buttonProgress,
  errorList: theme.errorList,
  checkboxContainer: {
    marginTop: theme.spacing(1)
  }
}));

function PaymentInfo(props) {
  let debug = false;
  const classes = useStyles();

  const [plansSet, setPlans] = useState(false);
  const [showingErrors, setShowingErrors] = useState(false);
  const [invoiceInterval, setInvoiceInterval] = useState(false);

  if (debug) console.log(props);

  const { too_many_users, billingInterval, billingIntervalDisabled, actions, activeStep, assocStates, dialogData, history, valid, scroller, gettingPaymentMethod, section, submitting, intentClientSecret, errors, done, invoice, agreed, subscriptionType } = props;
  // this flag only really exists to manage the too_many_users dialog box.
  const [addDialogOpened, setAddDialogOpened] = useState(too_many_users);

  let currentStep = 0;
  let lastStep = 0;

  if (section === 'add_subscription') {
    currentStep = 1;
    lastStep = 1;
  } else {
    currentStep = 3;
    lastStep = 2;
  }

  const handleBackClick = () => {
    scroller.scrollTo("scrollPoint", { duration: 500, smooth: true});
    setTimeout(() => {
      actions.setStep(lastStep);
    }, 500);
  }

  const handleNextClick = () => {
    actions.setSubmitting(true);
    actions.getPaymentIntent();
  }

  const handleAgreement = () => {
    actions.toggleAgreement();
    actions.checkPaymentInfoValid()
  }

  const setAdditionalDiscountDialogInfo = () => {
    actions.setDialoInfo({
      title: 'Contact Us To Get A Better Discount',
      body: () => {
        return (
          <>
            <Typography>
              If you would like to create an account for 10 or more users and would like to receive a discount of more than 15%, please <a href="/contact">contact us</a> and someone will promptly get back to you.
            </Typography>
          </>
        )
      }
    })
  };

  const additionalDiscountDialog = () => {
    setAdditionalDiscountDialogInfo();
    actions.toggleDialog();
    setAddDialogOpened(true)
  }
  
  const handleDialogClose = () => {
    if (errors.length > 0) {
      actions.resetErrors();
      setShowingErrors(false);
    }
    actions.toggleDialog();
  };

  const setErrorDialogInfo = () => {
    actions.setDialoInfo({
      title: 'Errors with submission',
      body: () => {
        const renderErrors = () => {
          const errorsHtml = [];
          errors.forEach((error, index) => {
            const errorHtml = <li key={`error-${index}`}>{error.message}</li>
            errorsHtml.push(errorHtml);
          });
          return errorsHtml;
        }
        return (
          <ul className={classes.errorList}>
            {renderErrors()}
          </ul>
        )
      }
    });
  }

  const creditCardProps = {
    actions,
    gettingPaymentMethod,
    intentClientSecret,
    submitting
  }

  const renderBillingCycle = () => {
    return (
      <>
        <Typography variant='subtitle1' align="center" className={classes.subtitle}>
          Billing Cycle
        </Typography>

        <BillingCycle actions={actions}  billingInterval={billingInterval} billingIntervalDisabled={billingIntervalDisabled} />
      </>
    )
  }

  useEffect(() => {
    // if the user is not allowed to be here yet
    if (activeStep < currentStep) {
      history.replace(`/${lastStep}`)
      return false;
    }
    // the first time the component loads, generate the plans from their previous selections in state
    if (!plansSet) {
      actions.initiatePlans();
      setPlans(true);
    }
    // check if the user has entered 10+ users
    if (too_many_users) {
      // this flag is to prevent render looping
      if (!addDialogOpened) additionalDiscountDialog();
    } else {
      // once the user selected another quantity, reset the flag so it can be shown again
      setAddDialogOpened(false)
    }

    if (!showingErrors && errors.length > 0) {
      setErrorDialogInfo();
      actions.toggleDialog();
      setShowingErrors(true);
    }

    if (done && Object.keys(invoice).length === 0 && !invoiceInterval) {
      const interval = setInterval(() => {
        actions.getInvoice();
      }, 2000);
      setInvoiceInterval(interval);
    } else if (Object.keys(invoice).length > 0) {
      clearInterval(invoiceInterval)
      scroller.scrollTo("scrollPoint", { duration: 500, smooth: true});
      setTimeout(() => {
        actions.setStep(4);
        if (invoice.status === "future") {
          history.replace(`/in_future`)
        } else {
          history.replace(`/${invoice.stripe_invoice_id}`)
        }
      }, 500);
    }
  });

  if (debug) console.log('PaymentInfo RENDER');

  const [stripePromise, setStripePromise] = useState(() => loadStripe(process.env.STRIPE_PUBLISHABLE_KEY));

  return (
    <Elements stripe={stripePromise}>


      { subscriptionType === 'student-sub'
        ? null
        : renderBillingCycle()
      }


      <Typography variant='subtitle1' align="center" className={classes.subtitle2}>
        Subscription Info
      </Typography>
      <SubscriptionInvoice {...props} />
      <Divider />

      <Typography variant='subtitle1' align="center" className={classes.subtitle2}>
        Credit/Debit Card Information
      </Typography>
      <CreditCard {...creditCardProps} />

      <FormControlLabel
        control={<Checkbox checked={agreed} onChange={handleAgreement} name="agreementCheck" />}
        className={classes.checkboxContainer}
        label={
          <Typography variant='body2'>
            I agree to the <Link href="/terms" target="_blank">Terms of Use</Link> and <Link href="/subscriber_agreement" target="_blank">Subscriber Agreement</Link>
          </Typography>
        }
      />

      { subscriptionType === 'student-sub'
        ? <p className={classes.studentMessage}>To activate your free trial account, a credit card is required. In August of the year of your graduation, a monthly recent graduate plan fee will automatically be charged to this credit card. You may cancel this at any time prior to August, or during the subscription service.</p>
        : null
      }

      <div className={classes.buttonContainer}>
        <Button
          className={classes.button}
          onClick={handleBackClick}
        >
          Back
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={handleNextClick}
          disabled={valid ? submitting || false : true}
        >
          Order Subscription
          { submitting && <CircularProgress size={24} className={classes.buttonProgress} /> }
        </Button>
      </div>
      <CustomDialog dialogData={dialogData} handleClose={handleDialogClose} />
    </Elements>
  );
}

PaymentInfo.propTypes = {
  activeStep: PropTypes.number.isRequired,
  assocStates: PropTypes.array.isRequired,
  billingInterval: PropTypes.string.isRequired,
  dialogData: PropTypes.object.isRequired,
};

export default PaymentInfo;