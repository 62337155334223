import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import IconButton from '@material-ui/core/IconButton';
import BackspaceIcon from '@material-ui/icons/Backspace';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import rgSelect from 'data/rgSelect';
import vmaSelect from 'data/vmaSelect';

import Button from '@material-ui/core/Button';
import AddCircleOutlinedIcon from '@material-ui/icons/AddCircleOutlined';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import InputSelect from 'components/Forms/Fields/InputSelect';
import InputText from 'components/Forms/Fields/InputText';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 300,
    // '& .MuiTableRow-root': {
    //   '&:last-child': {
    //     backgroundColor: '#EEE',
    //     '& .MuiTableCell-root': {
    //       borderBottom: 'none'
    //     }
    //   }
    // }
  },
  invoiceBG: {
    backgroundColor: '#fff',
    marginBottom: theme.spacing(2)
  },
  mobileBG: {
    backgroundColor: '#fff',
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1),
    paddingLeft: 0,
    paddingRight: 0,
  },
  quantityDropdown: {
    minWidth: 50,
    '& .MuiSelect-selectMenu': {
      textAlign: 'center'
    }
  },
  multiply: {
    float: 'left',
    fontSize: '20px',
    lineHeight: '18px',
  },
  MuiTableCell: {
    root: {
      borderBottom: 'none'
    }
  },
  MuiTableRow: {
    root: {
      backgroundColor: '#CCC'
    }
  },
  spacer: {
    borderBottom: 'none'
  },
  toolbar: {
    borderBottom: 'none',
    '& .MuiTableCell-root': {
      paddingLeft: 0,
      paddingRight: 0
    }
  },
  loneTotal: {
    '& .MuiTableCell-root': {
      textAlign: 'right'
    }
  },
  addButton: {
    color: theme.palette.success.dark,
    marginRight: 16,
    paddingLeft: 12,
    paddingRight: 12
  },
  addPromoButton: {
    marginRight: -10,
    '& .MuiButton-startIcon': {
      marginTop: 2,
    }
  },
  adminCouponButton: {
    marginRight: 0,
    '& .MuiButton-startIcon': {
      marginTop: 2,
    }
  },
  addVMAStateButton: {
    float: 'right',
    marginRight: 0,
  },
  removePromoButton: {
    color: theme.palette.error['dark'],
    padding: theme.spacing(1/2),
    marginLeft: theme.spacing(1/4),
    position: 'absolute',
    bottom: 4,
    '& .MuiSvgIcon-root': {
      fontSize: '1rem'
    }
  },
  boldText: {
    fontWeight: 'bold'
  }
}));

function dollarFormat(amount) {
  return `$${(amount/100).toFixed(2)}`;
}

function SubscriptionInvoice(props) {
  let debug = false;
  const classes = useStyles();
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('md'));

  if (debug) console.log(props);

  const { addGraduateYear, addVMAState, addVMAYear, assocStates, actions, plans, totals, billingInterval, subscriptionType, userData, promoError, promoSuccess, dialogData } = props;

  const rgOptions = rgSelect()[0].fields[0];
  rgOptions.options.pop();

  const handleQuantityChange = (planId, value) => {
    const bulkPlan = plans.filter(el => el.plan_type.includes('bulk_'))[0]
    const nonStandardTotal = plans.filter(el => !el.plan_type.includes('bulk_')).reduce((acc, cv) => {
      if (cv.plan_type === planId) {
        return acc + value
      } else {
        return acc + cv.quantity
      }
    }, 0);
    if (nonStandardTotal === 1 && bulkPlan.quantity < 1) {
      actions.setQuantity({ planId: bulkPlan.plan_type, value: 1 });
    } else if (nonStandardTotal === 0 && bulkPlan.quantity < 2) {
      actions.setQuantity({ planId: bulkPlan.plan_type, value: 2 });
    }

    actions.setQuantity({ planId, value });

    if (subscriptionType === 'admin') {
      actions.adminInvoiceChanged();
    } else {
      actions.invoiceChanged();
    }
    actions.checkPaymentInfoValid();
  }

  const renderMenuItems = (plan_type) => {
    if (plan_type === 'formulary') {
      return (
        [
          <MenuItem key={0} value={0}>0</MenuItem>,
          <MenuItem key={1} value={1}>1</MenuItem>,
        ]
      )
    } else if (plan_type === 'bulk_23' || plan_type === 'bulk_45' || plan_type === 'bulk_69') {

      let extraItems = []

      if (subscriptionType === 'admin') {
        extraItems = [
          <MenuItem key={0} value={0}>0</MenuItem>,
          <MenuItem key={1} value={1}>1</MenuItem>,
        ]
      } else {
        const nonStandardTotal = plans.filter(el => !el.plan_type.includes('bulk_')).reduce((acc, cv) => acc + cv.quantity, 0);
        if (nonStandardTotal === 1) {
          extraItems = [
            <MenuItem key={1} value={1}>1</MenuItem>,
          ]
        } else if (nonStandardTotal >= 2) {
          extraItems = [
            <MenuItem key={0} value={0}>0</MenuItem>,
            <MenuItem key={1} value={1}>1</MenuItem>
          ]
        }

      }

      let menuItems = [
        ...extraItems,
        <MenuItem key={2} value={2}>2</MenuItem>,
        <MenuItem key={3} value={3}>3</MenuItem>,
        <MenuItem key={4} value={4}>4</MenuItem>,
        <MenuItem key={5} value={5}>5</MenuItem>,
        <MenuItem key={6} value={6}>6</MenuItem>,
        <MenuItem key={7} value={7}>7</MenuItem>,
        <MenuItem key={8} value={8}>8</MenuItem>,
        <MenuItem key={9} value={9}>9</MenuItem>,
        <MenuItem key={10} value={10}>10</MenuItem>,
      ];

      return menuItems;

    } else {
      return ([
        <MenuItem key={0} value={0}>0</MenuItem>,
        <MenuItem key={1} value={1}>1</MenuItem>,
        <MenuItem key={2} value={2}>2</MenuItem>,
        <MenuItem key={3} value={3}>3</MenuItem>,
        <MenuItem key={4} value={4}>4</MenuItem>,
        <MenuItem key={5} value={5}>5</MenuItem>,
        <MenuItem key={6} value={6}>6</MenuItem>,
        <MenuItem key={7} value={7}>7</MenuItem>,
        <MenuItem key={8} value={8}>8</MenuItem>,
        <MenuItem key={9} value={9}>9</MenuItem>,
        <MenuItem key={10} value={10}>10</MenuItem>,
      ])
    }
  }

  const getPlanName = (plan) => {
    if (plan.plan_type === 'rg_1' || plan.plan_type === 'rg_2' || plan.plan_type === 'rg_3') {
      const fieldData = rgOptions;
      const indexMap = fieldData.indexMap;
      let year;
      let key = plan.plan_type.replace('rg_', '');
      for (let val in indexMap) {
        if (indexMap[val] === key) {
          year = val;
        }
      }
      return `${year} Year Recent Graduate`;
    } else if (subscriptionType !== "admin" && (plan.plan_type === 'bulk_23' || plan.plan_type === 'bulk_45' || plan.plan_type === 'bulk_69')) {
      return plan.stripe_product.name.split(' (')[0];
    } else {
      return plan.stripe_product.name;
    }
  }

  const renderPlanRows = () => {
    const content = plans.map((plan) => {

      if (subscriptionType === 'admin' && billingInterval === 'year' && (plan.plan_type === 'formulary' || plan.plan_type === 'student')) return false;

      return (
        <TableRow key={plan.plan_type}>
          <TableCell colSpan={2}>
            { getPlanName(plan)}
          </TableCell>
          <TableCell align="right">
            <Select disabled={(subscriptionType === 'multi-sub' || plan.plan_type === 'formulary' || subscriptionType === 'admin') ? false : true} className={classes.quantityDropdown} value={plan.quantity} onChange={(event) => { handleQuantityChange(plan.plan_type, event.target.value) }}>
              {renderMenuItems(plan.plan_type)}
            </Select>
          </TableCell>
          <TableCell align="right">
            <span className={classes.multiply}>&#215;</span>
            {dollarFormat(plan.price)}
          </TableCell>
        </TableRow>
      )
    });
    return content;
  }

  const handleNewGradYear = ({ key, value }) => {
    const fieldData = rgOptions;
    const yearIndex = fieldData.indexMap[value];
    actions.addGraduatePlanYear(yearIndex);
  }

  const handleNewVMAStateYear = ({ key, value }) => {
    actions.addVMAStateYear({key, value});
  }

  const handleAddCoupon = ({ key, value }) => {
    actions.adminAddCoupon({ key, value });
  }

  const handleAddingGraduatePlan = () => {
    actions.addGraduatePlanToInvoice();
    actions.invoiceChanged();
    actions.toggleDialog();
  }

  const handleAddingVMAPlan = () => {
    actions.addVMAPlanToInvoice();
    actions.invoiceChanged();
    actions.toggleDialog();
  }

  const handleCouponsDone = () => {
    actions.adminInvoiceChanged();
    actions.toggleDialog();
  }

  const setAddCouponsDialogInfo = () => {
    let savedAdminCoupon = totals.couponsInfo.length > 0 ? totals.couponsInfo[0].coupon_id : null;

    let subscriptionCoupons = props.subscriptionCoupons.reduce((acc, cv) => {
      const label = () => {
        if (cv.amount_off) {
          return `${cv.name} (${ dollarFormat(cv.amount_off) })`
        } else {
          return `${cv.name} (${ cv.percent_off }%)`
        }
      }
      const option = {
        value: cv.stripe_coupon_id,
        label: label(),
      }
      acc.push(option);
      return acc;
    }, []);

    let allCoupons = [
      // ...customerCoupons,
      ...subscriptionCoupons
    ];

    actions.setDialoInfo({
      title: 'Add Coupon',
      button: {
        text: 'Done',
        variant: 'contained',
        onClick: handleCouponsDone
      },
      body: () => {
        const customer_coupon_props = {
          label: 'Add A Customer Coupon',
          options: allCoupons,
          changeAction: handleAddCoupon,
          disabled: false,
          savedValue: savedAdminCoupon,
          id: 'customer',
        };
        return (
          <>
            <InputSelect { ...customer_coupon_props } />
          </>
        )
      }
    });
  }

  const setAddGraduateDialogInfo = () => {

    actions.setDialoInfo({
      title: 'Add A Recent Graduate Plan',
      button: {
        text: 'Add Grad',
        variant: 'contained',
        onClick: handleAddingGraduatePlan
      },
      body: () => {
        const field_props = {
          ...rgOptions,
          changeAction: handleNewGradYear,
          disabled: false,
          savedValue: addGraduateYear
        }
        return (
          <>
            <InputSelect { ...field_props } />
          </>
        )
      }
    });
  }

  const openAddPromoDialog = () => {
    actions.setDialoInfo({
      title: 'Add A Promotional Code',
      id: 'promoCode',
      button: {
        text: 'Add Promo',
        variant: 'contained',
        onClick: () => { actions.getPromoCode() }
      },
      body: (id) => {
        const field_props = {
          disabled: false,
          changeAction: step3FieldChange,
          label: 'Promo Code',
          errorMessage: promoError,
          error: promoError ? true : false,
          id,
          savedValue: '',
          type: 'text',
        }
        return (
          <>
            <InputText { ...field_props } />
          </>
        )
      }
    });

    if (!dialogData.show) {
      actions.resetErrors();
      actions.toggleDialog();
    }

  }

  const openAddVMADialogInfo = () => {
    actions.setDialoInfo({
      title: 'Add a State/Provincial VMA Member Plan',
      button: {
        text: 'Add VMA Member',
        variant: 'contained',
        onClick: handleAddingVMAPlan
      },
      body: () => {
        const dropDowns = vmaSelect(assocStates)[0];
        const fieldsArray = [];

        dropDowns.forEach((dropdown, index) => {
          if (index === 0) dropdown.fields[0].options.pop()

          const field_props = {
            ...dropdown.fields[0],
            changeAction: handleNewVMAStateYear,
            disabled: false,
            savedValue: index === 0 ? addVMAState : addVMAYear,
            key: `dropdown-${index}`
          }
          fieldsArray.push(<InputSelect { ...field_props } />)
        });

        return (
          <>
            { fieldsArray }
          </>
        )
      }
    });
    actions.toggleDialog();
  }

  const step3FieldChange = ({ key, value }) => {
    actions.step3FieldChange({ key, value });
  }

  const renderPromoRow = () => {
    const rows = [];
    rows.push(
      <TableRow key="promoRow">
        <TableCell colSpan={2}>Discount Promo Code:</TableCell>
        <TableCell align="right">
          <Button
            size="small"
            className={`${classes.addButton} ${classes.addPromoButton}`}
            onClick={() => { openAddPromoDialog() }}
            startIcon={<AddCircleOutlinedIcon />}
          >
            add promo code
          </Button>
        </TableCell>
      </TableRow>
    )

    return rows;
  }

  const invoiceChanged = () => {
    if (subscriptionType === 'admin') {
      actions.adminInvoiceChanged();
    } else {
      actions.invoiceChanged();
    }
  }

  const removePromo = () => {
    actions.removePromo();
    invoiceChanged();
  }

  const removePromoButton = () => (
    <IconButton aria-label="close" className={classes.removePromoButton} onClick={removePromo}>
      <BackspaceIcon />
    </IconButton>
  )

  const renderDiscountRows = () => {
    const rows = [];
    let extraClass = '';
    if (subscriptionType === 'student-sub') extraClass = classes.boldText;
    
    totals.couponsInfo.forEach(coupon => {
      rows.push(
        <TableRow key={coupon.coupon_name}>
          <TableCell colSpan={2} className={extraClass}>
            { coupon.coupon_name }
            { subscriptionType === 'student-sub'
              ? null
              : removePromoButton()
            }
          </TableCell>
          <TableCell align="right" className={extraClass}>{ coupon.coupon_discount }</TableCell>
        </TableRow>
      )
    });
    return rows;
  }

  const renderAdminToolbar = () => (
    <TableRow className={classes.toolbar}>
      <TableCell colSpan={5} align="right">
        <Button
          size="small"
          className={`${classes.addButton} ${classes.adminCouponButton}`}
          onClick={() => { handleAddCouponsClick() }}
          startIcon={<AddCircleOutlinedIcon />}
        >
          add coupon
        </Button>
      </TableCell>
    </TableRow>
  )

  const renderToolbar = () => (
    <TableRow className={classes.toolbar}>
      <TableCell colSpan={5}>
        <Button
          size="small"
          className={classes.addButton}
          onClick={() => { handleAddRGClick() }}
          startIcon={<AddCircleOutlinedIcon />}
        >
          add a recent graduate
        </Button>

        <Button
          size="small"
          className={`${classes.addButton} ${classes.addVMAStateButton}`}
          onClick={() => { openAddVMADialogInfo() }}
          startIcon={<AddCircleOutlinedIcon />}
        >
          add a state/provincial VMA Member
        </Button>
      </TableCell>
    </TableRow>
  )

  const renderSubtotalRow = () => (
    <TableRow>
      <TableCell colSpan={2}>Subtotal</TableCell>
      <TableCell align="right">{dollarFormat(totals.subtotal)}</TableCell>
    </TableRow>
  )

  const handleAddCouponsClick = () => {
    setAddCouponsDialogInfo();
    actions.toggleDialog();
  }

  const handleAddRGClick = () => {
    setAddGraduateDialogInfo();
    actions.toggleDialog();
  }

  useEffect(() => {
    if (promoSuccess) {
      invoiceChanged();
      actions.toggleDialog();
    }
    // openAddPromoDialog()
    
    if (dialogData.show) {
      openAddPromoDialog()
    }
  }, [promoError, promoSuccess]);

  if (debug) console.log('SubscriptionInvoice RENDER');

  return (
    <TableContainer component={Paper} elevation={1} className={ mobileView ? classes.mobileBG : classes.invoiceBG}>
      <Table  className={classes.table} aria-label="spanning table">
        <TableHead>
          <TableRow>
            <TableCell colSpan={2}>Subscription Plan</TableCell>
            <TableCell align="right" width={70}>Qty.</TableCell>
            <TableCell align="right" width={200}>Price</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {renderPlanRows()}

          { subscriptionType === 'multi-sub'
            ? renderToolbar()
            : null
          }

          { subscriptionType === 'admin' && !userData.coupon_id.value
            ? renderAdminToolbar()
            : null
          }

          <TableRow className={classes.spacer} height={30}>
            <TableCell rowSpan={5} width={100} />
            <TableCell colSpan={3}></TableCell>
          </TableRow>

          { renderSubtotalRow() }

          { totals.couponsInfo.length > 0
            ? renderDiscountRows()
            : renderPromoRow()
          }
          <TableRow className={ totals.couponsInfo.length > 0 ? null : classes.loneTotal }>
            <TableCell colSpan={2}>
              { totals.couponsInfo.length > 0
                ? <strong>Total</strong>
                : <strong>Total:</strong>
              }
            </TableCell>
            <TableCell align="right"><strong>{dollarFormat(totals.total || 0)}/{billingInterval}</strong></TableCell>
          </TableRow>
        </TableBody>

      </Table>
    </TableContainer>
  );
}

SubscriptionInvoice.propTypes = {
  // formData: PropTypes.object.isRequired,
  assocStates: PropTypes.array.isRequired,
  billingInterval: PropTypes.string.isRequired,
  addGraduateYear: PropTypes.string.isRequired,
  addVMAState: PropTypes.string.isRequired,
  addVMAYear: PropTypes.string.isRequired,
  actions: PropTypes.object.isRequired,
};

export default SubscriptionInvoice;