import { all } from 'redux-saga/effects';

import { SignUpSagas } from './signup';
import { PasswordSagas } from './password';
import { AccountInfoSagas } from './myaccount';

export default function* rootSaga() {
  yield all([
    ...SignUpSagas,
    ...PasswordSagas,
    ...AccountInfoSagas,
  ])
}