import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Admin from './Admin';

import * as actions from './actions';
import * as sharedActions from '../Shared/actions';
let debug = false;

function mapStateToProps(state) {
  if (debug) console.log('Admin - mapStateToProps');
  return {
    assocStates: state.globalReducer.assocStates,
    errors: state.signupFormReducer.paymentInfo.errors,
    userData: state.myAccountFormReducer.user,
    dialogData: state.globalReducer.dialog,
    progressData: state.signupFormReducer.progress,
    paymentInfo: state.signupFormReducer.paymentInfo,
    subscriptionType: state.signupFormReducer.subscriptionType.subscription_type.value
  };
}

function mapDispatchToProps(dispatch) {
  if (debug) console.log('Admin - mapDispatchToProps');
  const adminActions = bindActionCreators(actions, dispatch);
  const commonActions = bindActionCreators(sharedActions, dispatch);
  return {
    actions: {
      ...adminActions,
      ...commonActions,
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Admin);