import * as types from './constants';

let debug = false;

export function initiateAdminPlans(data) {
  if (debug) console.log("Admin - initiateAdminPlans");
  return {
    type: types.ADMIN_INITIATE_ITEMS,
    data
  };
}

export function adminAddCoupon(data) {
  if (debug) console.log("Admin - adminAddCoupon");
  return {
    type: types.ADMIN_ADD_COUPON,
    data
  };
}

export function adminInvoiceChanged(data) {
  if (debug) console.log("Admin - adminInvoiceChanged");
  return {
    type: types.ADMIN_INVOICE_CHANGED,
    data
  };
}