import initialState from '../initialState';
import rgSelect from 'data/rgSelect';
import {subYears, getYear, differenceInMonths} from 'date-fns';

import {
  ADD_GRADUATE_PLAN_YEAR,
  ADD_VMA_STATE_YEAR,
  ADD_GRADUATE_PLAN_TO_INVOICE,
  ADD_VMA_PLAN_TO_INVOICE,
  BOOTSTRAP_COUPONS,
  BOOTSTRAP_PRODUCTS,
  INITIATE_PLAN_ITEMS,
  INVOICE_CHANGED,
  // LOAD_USER_COUPON,
  GET_INVOICE_SUCCESS,
  SET_STEP,
  SET_STEP_2_REQUIRED,
  STEP_1_FIELD_CHANGE,
  STEP_2_FIELD_CHANGE,
  BOOTSTRAP_DEFAULT_SUBSCRIPTION,
  RESET_ERRORS,
  REMOVE_ERRORS,
  STUDENT_EMAIL_CHECK_SUCCESS,
  STUDENT_EMAIL_CHECK_FAIL,
  TOGGLE_AGREEMENT,
  LOAD_SUBSCRIPTION_ACCOUNTINFO,
  RESET_REQUIRED,
} from 'components/SignUp/constants';

import {
  CHECK_PAYMENT_INFO_VALID,
  SET_PAYMENT_METHOD,
  SET_STRIPE_VALID,
  GET_INTENT_SECRET_SUCCESS,
  GET_PROMO_CODE_SUCCESS,
  GET_PROMO_CODE_FAIL,
  REMOVE_PROMO_CODE,
  SET_SUBMITTING,
  SUBSCRIPTION_SEND_ERROR,
  SUBSCRIPTION_SEND_SUCCESS,
  STEP_3_FIELD_CHANGE,
  SET_BILLING_INTERVAL,
  SET_QUANTITY,
} from 'components/Shared/constants';

import { ADMIN_ADD_COUPON, ADMIN_INITIATE_ITEMS, ADMIN_INVOICE_CHANGED } from 'components/Admin/constants';

let debug = false;

function checkCouponValidityForProduct(couponInfo, billingInterval, plans) {
  if (couponInfo.coupon_tag === 'student') return true;
  if (!couponInfo.coupon_tag.includes(billingInterval)) return false;

  const couponProducts = couponInfo.coupon_applies_to.split(',');
  const productIntersection = plans.filter(plan => couponProducts.includes(plan.stripe_product.stripe_product_id));
  if (productIntersection.length === 0) return false;
  return true;
}


function getCouponDiscountInfo(coupon, invoiceSubtotal, discountedSubtotal) {
  if (!invoiceSubtotal) return(0);

  let discountNumber = getDiscountAmount(coupon, discountedSubtotal);
  let discountPercentage = (discountNumber / invoiceSubtotal) * 100;

  return {
    discountNumber,
    discountText: `-$${(discountNumber / 100).toFixed(2)} (${discountPercentage.toFixed(2) || 100}%)`,
  }
}

function getDiscountAmount(coupon, subtotal) {
  let discount_amount;

  discount_amount = coupon.amount_off ? parseInt(coupon.amount_off) : (subtotal * coupon.percent_off) / 100;

  return discount_amount;
}

function findBestSubscriptionCoupon(possible_subscription_coupons, subtotal) {
  if (!possible_subscription_coupons || possible_subscription_coupons.length === 0) return null;
  if (possible_subscription_coupons.length === 1) return possible_subscription_coupons[0];
  possible_subscription_coupons.sort(function(a, b) {
    return getDiscountAmount(b, subtotal) - getDiscountAmount(a, subtotal);
  });
  return possible_subscription_coupons[0];
}

function getTotals(coupon, couponProducts, plans, billingInterval) {
  let subtotal = 0;
  let discountedSubtotal = 0;

  plans.forEach(plan => {
    const prices = plan.stripe_product.prices;
    const price = prices.find(el => el.interval === billingInterval);
    if (price) {
      plan.price = price.amount;
      const planSubtotal = plan.quantity * price.amount;
      plan.subtotal = planSubtotal;
      subtotal += planSubtotal;
      if (couponProducts.includes(plan.plan_type)) {
        discountedSubtotal += planSubtotal;
      }
    }
  });

  let discount = 0;
  let couponsInfo = [];

  if (coupon) {
    const {discountNumber, discountText} = getCouponDiscountInfo(coupon, subtotal, discountedSubtotal);

    discount += discountNumber;
    couponsInfo.push({
      coupon_type: 'subscription',
      coupon_id: coupon.stripe_coupon_id,
      coupon_name: coupon.name,
      coupon_discount: discountNumber ? discountText : 'N/A',
      coupon_tag: coupon.coupon_tag,
      coupon_applies_to: coupon.applies_to,
    });
  }

  const total = subtotal - (discount || 0);

  return ({newPlans: plans, invoiceSubtotal: subtotal, total, couponsInfo});
}

function getInvoiceTotals(available_coupons, invoiceSubtotal) {

  let discount = 0;
  let couponsInfo = [];
  
  available_coupons.forEach(coupon => {
    if (!coupon) return;
    const {discountNumber,discountText} = getCouponDiscountInfo(coupon, invoiceSubtotal);
    discount += discountNumber;
    couponsInfo.push({
      coupon_type: coupon.coupon_type === 'customer' ? 'customer' : 'subscription',
      coupon_id: coupon.stripe_coupon_id,
      coupon_name: coupon.name,
      coupon_discount: discountText
    });
  });

  const total = invoiceSubtotal - (discount || 0);
  return {total, couponsInfo};
}
// VMA and RG users will use fake coupons since their discount comes from different products.
function getInvoiceCoupons(invoiceInfo) {
  const { plans, coupons, subType, invoiceSubtotal } = invoiceInfo;

  let available_coupons = [];
  let extra_coupon;
  let primaryPlan = plans[0];
  let possible_subscription_coupons = coupons.filter(coupon => {
    return coupon.applies_to && coupon.applies_to.split(',').includes(primaryPlan.stripe_product.stripe_product_id) && coupon.coupon_type !== 'customer' && coupon.coupon_type !== 'admin';
  });
  let too_many_users = false;

  if (subType === 'multi-sub') {
    const user_count = countInvoiceUsers(plans);
    switch (true) {
      case user_count < 2:
        extra_coupon = {};
        break;
      case user_count < 4:
        extra_coupon = coupons.find(el => el.coupon_tag === 'bulk_5');
        break;
      case user_count < 6:
        extra_coupon = coupons.find(el => el.coupon_tag === 'bulk_10');
        break;
      case user_count < 10:
        extra_coupon = coupons.find(el => el.coupon_tag === 'bulk_15');
        break;
      default:
        extra_coupon = coupons.find(el => el.coupon_tag === 'bulk_15');
        too_many_users = true;
        break;
    }
    possible_subscription_coupons.push(extra_coupon);
  }
  
  let bestSubscriptionCoupon = findBestSubscriptionCoupon(possible_subscription_coupons, invoiceSubtotal);
  if (bestSubscriptionCoupon) available_coupons.push(bestSubscriptionCoupon);

  // return {available_coupons, too_many_users};
  return { available_coupons: coupons, too_many_users };
}

function countInvoiceUsers(plans) {
  const user_count = plans.reduce((acc, cv) => {
    acc += cv.quantity;
    return acc;
  }, 0);
  return user_count;
}

const multiPlans = {
  'bulk_23': [1,2,3], // 1 is in there just in case
  'bulk_45': [4,5], 
  'bulk_69': [6,7,8,9,10,11,12,13,14,15,16,17,18,19,20] // extra just in case
};

export default function formReducer(state = initialState.forms.signup, action) {
  let data, newAccountInfoData, newProgressData, newState, newSubTypeData, newPlans, products, too_many_users, total, billingInterval, subType, coupon, coupons, graduationYear, plans, appliesToArray, couponsInfo, couponProducts, invoiceSubtotal, plansClone, productTypes, invoiceValid, errors, newPaymentInfoData, check_id, newPlanObject;

  let checkDate = new Date();
  let oneYearAgo = subYears(checkDate, 1);

  switch (action.type) {

    case ADMIN_ADD_COUPON:
      if (debug) console.log("signup Reducer - ADMIN_ADD_CUSTOMER_COUPON");
      data = action.data;
      coupons = state.coupons;
      products = state.products;
      productTypes = state.productTypes;

      let selectedCoupon = coupons.find(el => el.stripe_coupon_id === data.value);

      appliesToArray = [];
      couponProducts = [];

      if (selectedCoupon.applies_to) {
        appliesToArray = selectedCoupon.applies_to.split(',');

        couponProducts = appliesToArray.reduce((acc, cv) => {
          let allowedProduct = products.find(el => el.stripe_product_id === cv);
          if (allowedProduct) acc.push(allowedProduct.plan_type);
          return acc;
        }, []);
      }

      if (couponProducts.length === 0) couponProducts = [...productTypes];

      newState = {
        ...state,
        coupon: selectedCoupon,
        couponProducts,
        paymentInfo: {
          ...state.paymentInfo,
        }
      }

      return newState;

    case ADMIN_INITIATE_ITEMS:
      if (debug) console.log("signup Reducer - ADMIN_INITIATE_ITEMS");

      data = action.data;

      plans = [];
      subType = state.subscriptionType.subscription_type.value;
      products = state.products;
      coupons = state.coupons;
      coupon = state.coupon;
      couponProducts = state.couponProducts;
      billingInterval = state.paymentInfo.billingInterval;
      productTypes = [];

      products.forEach(product => {
        productTypes.push(product.plan_type);
        plans.push({
          quantity: 0,
          plan_type: product.plan_type,
          stripe_product: product,
        })
      });

      ({newPlans, invoiceSubtotal} = getTotals(coupon, couponProducts, plans, billingInterval));
      // let customerCoupons = coupons.filter(coupon => coupon.coupon_type === 'customer');
      let subscriptionCoupons = coupons.filter(coupon => coupon.coupon_type !== 'customer');

      newState = {
        ...state,
        productTypes,
        paymentInfo: {
          ...state.paymentInfo,
          // customerCoupons,
          subscriptionCoupons,
          plans: newPlans,
          totals: state.paymentInfo.totals,
        }
      }

      return newState;

    case ADMIN_INVOICE_CHANGED:
      if (debug) console.log("signup Reducer - ADMIN_INVOICE_CHANGED");

      subType = state.subscriptionType.subscription_type.value;
      coupons = state.coupons;
      coupon = state.coupon;
      plansClone = JSON.parse(JSON.stringify(state.paymentInfo.plans));
      couponProducts = state.couponProducts;
      billingInterval = state.paymentInfo.billingInterval;
      invoiceValid = state.paymentInfo.adminSendInvoiceValid;

      // let adminCoupons = state.paymentInfo.adminCoupons;

      ({newPlans, invoiceSubtotal, total, couponsInfo} = getTotals(coupon, couponProducts, plansClone, billingInterval));

      // ({total, couponsInfo} = getInvoiceTotals(adminCoupons, invoiceSubtotal));

      if (newPlans.find(el => el.quantity)) {
        invoiceValid = true;
      } else {
        invoiceValid = false;
      }

      newState = {
        ...state,
        paymentInfo: {
          ...state.paymentInfo,
          plans: newPlans,
          adminSendInvoiceValid: invoiceValid,
          totals: {
            subtotal: invoiceSubtotal,
            total,
            couponsInfo: couponsInfo,
          },
          too_many_users
        }
      }
      return newState;

    case ADD_GRADUATE_PLAN_TO_INVOICE:
      if (debug) console.log("signup Reducer - ADD_GRADUATE_PLAN_TO_INVOICE");

      data = action.data;

      newPlans = JSON.parse(JSON.stringify(state.paymentInfo.plans));
      const addGraduateYear = state.paymentInfo.addGraduateYear;
      products = state.products;
      newPlanObject = {};

      switch (addGraduateYear) {
        case "1":
          newPlanObject = {
            quantity: 1,
            plan_type: 'rg_1',
            stripe_product: products.find(el => el.plan_type === 'rg_1'),
          };
          check_id = 'rg_1';
          break;
        case "2":
          newPlanObject = {
            quantity: 1,
            plan_type: 'rg_2',
            stripe_product: products.find(el => el.plan_type === 'rg_2'),
          };
          check_id = 'rg_2';
          break;
        case "3":
          newPlanObject = {
            quantity: 1,
            plan_type: 'rg_3',
            stripe_product: products.find(el => el.plan_type === 'rg_3'),
          };
          check_id = 'rg_3';
          break;
        default:
          break;
      }

      if(!newPlans.find(el => el.plan_type === check_id)) {
        newPlans.push(newPlanObject);
      }

      newState = {
        ...state,
        paymentInfo: {
          ...state.paymentInfo,
          plans: newPlans,
          addGraduateYear: '',
        }
      }
      return newState;

    case ADD_VMA_PLAN_TO_INVOICE:
      if (debug) console.log("signup Reducer - ADD_VMA_PLAN_TO_INVOICE");

      data = action.data;

      newPlans = JSON.parse(JSON.stringify(state.paymentInfo.plans));
      const addVMAYear = parseInt(state.paymentInfo.addVMAYear);

      products = state.products;
      newPlanObject = {};

      if (addVMAYear && addVMAYear >= getYear(oneYearAgo)) {
        newPlanObject = {
          quantity: 1,
          plan_type: 'vma_new',
          stripe_product: products.find(el => el.plan_type === 'vma_new'),
        };
      } else {
        newPlanObject = {
          quantity: 1,
          plan_type: 'vma',
          stripe_product: products.find(el => el.plan_type === 'vma'),
        };
      }

      if(!newPlans.find(el => el.plan_type === newPlanObject.plan_type)) {
        newPlans.push(newPlanObject);
      }

      newState = {
        ...state,
        paymentInfo: {
          ...state.paymentInfo,
          plans: newPlans,
          addVMAState: '',
          addVMAYear: '',
        }
      }
      return newState;

    case ADD_GRADUATE_PLAN_YEAR:
      if (debug) console.log("signup Reducer - ADD_GRADUATE_PLAN_YEAR");

      data = action.data;

      newPaymentInfoData = {
        ...state.paymentInfo,
        addGraduateYear: data
      }

      newState = {
        ...state,
        paymentInfo: {
          ...newPaymentInfoData
        }
      }
      return newState;

    case ADD_VMA_STATE_YEAR:
      if (debug) console.log("signup Reducer - ADD_VMA_STATE_YEAR");

      data = action.data;

      newPaymentInfoData = {
        ...state.paymentInfo,
      }

      if (data.key === 'association_state') {
        newPaymentInfoData['addVMAState'] = data.value;
      } else {
        newPaymentInfoData['addVMAYear'] = String(data.value);
      }

      newState = {
        ...state,
        paymentInfo: {
          ...newPaymentInfoData
        }
      }
      return newState;

    case BOOTSTRAP_COUPONS:
      data = action.data;
      let parsedCoupons = JSON.parse(data);

      parsedCoupons.forEach(coupon => {
        coupon.coupon_type = 'subscription'
      });

      return {
        ...state,
        coupons: parsedCoupons
      };

    case BOOTSTRAP_DEFAULT_SUBSCRIPTION:
      if (debug) console.log("signup Reducer - BOOTSTRAP_DEFAULT_SUBSCRIPTION");

      data = action.data;

      newSubTypeData = {
        ...state.subscriptionType,
        valid: true
      }

      newSubTypeData['subscription_type'] = {
        value: data
      };

      newState = {
        ...state,
        subscriptionType: {
          ...newSubTypeData
        }
      }
      return newState;

    case BOOTSTRAP_PRODUCTS:
      data = action.data
      return {
        ...state,
        products: JSON.parse(data)
      };

    case INITIATE_PLAN_ITEMS:
      if (debug) console.log("signup Reducer - INITIATE_PLAN_ITEMS");

      data = action.data;

      plans = [];
      subType = state.subscriptionType.subscription_type.value;
      products = state.products;
      coupon = false;
      couponProducts = [];
      let previous_products = state.previous_products;
      billingInterval = state.paymentInfo.billingInterval;
      let billingIntervalDisabled = false;
      productTypes = [];

      products.forEach(product => {
        productTypes.push(product.plan_type);
      });

      if (previous_products && previous_products.length > 0) {
        let plan_type;
        previous_products.forEach(function(product){
          plan_type = product.plan_type;
          if (product.plan_type === 'vma_new') plan_type = 'vma';
          if (product.plan_type === 'rg_1') plan_type = 'rg_2';
          if (product.plan_type === 'rg_2') plan_type = 'rg_3';
          if (product.plan_type === 'rg_3') plan_type = 'standard';
          const existing_plan = plans.find(el => el.plan_type == plan_type);
          if (existing_plan) {
            existing_plan.quantity = existing_plan.quantity + product.quantity;
          } else {
            plans.push({
              quantity: product.quantity,
              plan_type: plan_type,
              stripe_product: products.find(el => el.plan_type === plan_type),
            });
          }
          billingInterval = product.interval;
        })

      } else {

        switch (subType) {
          case 'standard-sub':
            plans.push({
              quantity: 1,
              plan_type: 'standard',
              stripe_product: products.find(el => el.plan_type === 'standard'),
            });
            break;
          case 'multi-sub':
            plans.push({
              quantity: 2,
              plan_type: 'bulk_23',
              stripe_product: products.find(el => el.plan_type === 'bulk_23'),
            });
            break;
          case 'rg-sub':
            graduationYear = state.accountInfo.graduation_year.value;
            const fieldData = rgSelect()[0].fields[0];
            const yearIndex = fieldData.indexMap[graduationYear];
            let planId;
            switch (yearIndex) {
              case '1':
                planId = 'rg_1';
                break;
              case '2':
                planId = 'rg_2';
                break;
              case '3':
                planId = 'rg_3';
                break;
              default:
                break;
            }
            plans.push({
              quantity: 1,
              plan_type: planId,
              stripe_product: products.find(el => el.plan_type === planId),
            });
            break;
          case 'vma-sub':
            let membershipYear = state.accountInfo.join_year ? state.accountInfo.join_year.value : false;
            if (membershipYear && membershipYear >= getYear(oneYearAgo)) {
              plans.push({
                quantity: 1,
                plan_type: 'vma',
                stripe_product: products.find(el => el.plan_type === 'vma_new'),
              });
            } else {
              plans.push({
                quantity: 1,
                plan_type: 'vma',
                stripe_product: products.find(el => el.plan_type === 'vma'),
              });
            }
            break;
          case 'student-sub':
            billingIntervalDisabled = true;
            billingInterval = 'month';
            coupon = state.coupons[0];
            plans.push({
              quantity: 1,
              plan_type: 'student',
              stripe_product: products.find(el => el.plan_type === 'student'),
            }, {
              quantity: 0,
              plan_type: 'formulary',
              stripe_product: products.find(el => el.plan_type === 'formulary'),
            });
            couponProducts.push('student');
            break;
          default:
            break;
        }

      }

      ({newPlans, invoiceSubtotal, total, couponsInfo} = getTotals(coupon, couponProducts, plans, billingInterval));

      if (subType === 'multi-sub') {
        too_many_users = countInvoiceUsers(newPlans) < 10 ? false : true;
      }

      // ({ available_coupons, too_many_users } = getInvoiceCoupons({plans: newPlans, coupons, subType, invoiceSubtotal}));
      // ({total, couponsInfo} = getInvoiceTotals(coupons, invoiceSubtotal));

      newState = {
        ...state,
        productTypes,
        coupon,
        couponProducts,
        paymentInfo: {
          ...state.paymentInfo,
          billingInterval,
          billingIntervalDisabled,
          plans,
          totals: {
            subtotal: invoiceSubtotal,
            total,
            couponsInfo,
          },
          too_many_users
        }
      }

      return newState;

    case CHECK_PAYMENT_INFO_VALID:
      plansClone = JSON.parse(JSON.stringify(state.paymentInfo.plans));
      let stripeValid = state.paymentInfo.stripeValid;
      subType = state.subscriptionType.subscription_type.value;
      
      invoiceValid = false;
      let agreed = state.paymentInfo.agreed;

      if (plansClone.find(el => el.quantity)) {
        invoiceValid = true;
      } else {
        invoiceValid = false;
      }

      if (subType === 'admin') {
        newValid = stripeValid && invoiceValid
      } else {
        newValid = stripeValid && invoiceValid && agreed
      }

      newState = {
        ...state,
        paymentInfo: {
          ...state.paymentInfo,
          valid: newValid
        }
      }
      return newState;

    case SET_STRIPE_VALID:
      data = action.data;

      newState = {
        ...state,
        paymentInfo: {
          ...state.paymentInfo,
          stripeValid: data
        }
      }
      return newState;

    case SET_SUBMITTING:
      data = action.data;

      newState = {
        ...state,
        paymentInfo: {
          ...state.paymentInfo,
          submitting: data
        }
      }
      return newState;

    case GET_INVOICE_SUCCESS:
      data = action.payload.data;

      newState = {
        ...state,
        finishScreen: {
          ...state.finishScreen,
          invoice: data.invoice
        }
      }
      return newState;

    case STUDENT_EMAIL_CHECK_SUCCESS:
      data = action.payload.data;

      newCompletedRequired = [
        ...state.accountInfo.completedRequired,
        'school_name'
      ]

      newState = {
        ...state,
        accountInfo: {
          ...state.accountInfo,
          completedRequired: newCompletedRequired,
          school_name: {
            value: data.university
          }
        }
      }
      return newState;

    case STUDENT_EMAIL_CHECK_FAIL:
      errors = action.data.errors;

      newCompletedRequired = [
        ...state.accountInfo.completedRequired
      ]

      let arrIndex = newCompletedRequired.indexOf('school_name');
      if (arrIndex > -1) {
        newCompletedRequired.splice(arrIndex, 1);
      }

      newState = {
        ...state,
        accountInfo: {
          ...state.accountInfo,
          completedRequired: newCompletedRequired,
          email: {
            value: '',
            error: true,
            errorMessage: errors[0].message
          },
          school_name: {
            value: '',
          }
        }
      }

      return newState;
      
    case GET_PROMO_CODE_SUCCESS:
      if (debug) console.log('GET_PROMO_CODE_SUCCESS');
      data = action.payload.data;
      products = state.products;
      productTypes = state.productTypes;
      couponProducts = [];

      let promoCoupon = data.promo.coupon;

      if (promoCoupon.applies_to) {
        appliesToArray = promoCoupon.applies_to.split(',');

        couponProducts = appliesToArray.reduce((acc, cv) => {
          let allowedProduct = products.find(el => el.stripe_product_id === cv);
          if (allowedProduct) acc.push(allowedProduct.plan_type);
          return acc;
        }, []);
      }

      if (couponProducts.length === 0) couponProducts = [...productTypes];
      
      newState = {
        ...state,
        coupon: data.promo.coupon,
        couponProducts,
        paymentInfo: {
          ...state.paymentInfo,
          promoSuccess: true,
        }
      }
      
      return newState;
  
    case GET_PROMO_CODE_FAIL:
      if (debug) console.log('GET_PROMO_CODE_FAIL');
      errors = action.data.errors;
      
      newState = {
        ...state,
        paymentInfo: {
          ...state.paymentInfo,
          promoError: errors[0].message,
          // promoCode: ''
        }
      }
      
      return newState;

    case REMOVE_PROMO_CODE:
      if (debug) console.log('REMOVE_PROMO_CODE');
      
      newState = {
        ...state,
        couponProducts: [],
        coupon: false,
        paymentInfo: {
          ...state.paymentInfo,
          promoError: '',
          promoCode: '',
          promoSuccess: false,
        }
      }
      
      return newState;
    
    case INVOICE_CHANGED:
      if (debug) console.log("signup Reducer - INVOICE_CHANGED");

      subType = state.subscriptionType.subscription_type.value;
      coupon = state.coupon;
      couponProducts = state.couponProducts;
      plansClone = JSON.parse(JSON.stringify(state.paymentInfo.plans));
      billingInterval = state.paymentInfo.billingInterval;

      ({newPlans, invoiceSubtotal, total, couponsInfo} = getTotals(coupon, couponProducts, plansClone, billingInterval));

      if (subType === 'multi-sub') {
        too_many_users = countInvoiceUsers(newPlans) < 10 ? false : true;
      }

      let couponValid = true;

      if (couponsInfo.length > 0) {
        couponValid = checkCouponValidityForProduct(couponsInfo[0], billingInterval, newPlans);
      }

      if (!couponValid) {
        couponsInfo = [];
        coupon = false;
        couponProducts = [];
      }

      newState = {
        ...state,
        coupon,
        couponProducts,
        paymentInfo: {
          ...state.paymentInfo,
          plans: newPlans,
          totals: {
            subtotal: invoiceSubtotal,
            total,
            couponsInfo
          },
          promoSuccess: false,
          too_many_users
        }
      }
      return newState;
  
    case RESET_ERRORS:
      newState = {
        ...state,
        paymentInfo: {
          ...state.paymentInfo,
          errors: [],
          promoError: '',
        }
      }
      return newState;

    case REMOVE_ERRORS:

      newAccountInfoData = {
        ...state.accountInfo
      };

      Object.keys(newAccountInfoData).forEach((key) => {
        if (newAccountInfoData[key]['error']) {
          newAccountInfoData[key] = {
            value: newAccountInfoData[key]['value'],
            error: false,
            errorMessage: ''
          }
        }
      });
  
      newState = {
        ...state,
        accountInfo: {
          ...newAccountInfoData
        }
      }
      return newState;

    case RESET_REQUIRED:
      newState = {
        ...state,
        accountInfo: initialState.forms.signup.accountInfo
      }
      return newState
        

    case SET_BILLING_INTERVAL:
      if (debug) console.log("signup Reducer - SET_BILLING_INTERVAL");

      data = action.data;
      const newInterval = data ? 'month' : 'year';

      newState = {
        ...state,
        paymentInfo: {
          ...state.paymentInfo,
          billingInterval: newInterval,
        }
      }
      return newState;
    
    case SET_STEP_2_REQUIRED:
      if (debug) console.log("signup Reducer - SET_STEP_2_REQUIRED");

      data = action.data;

      newCompletedRequired = [];

      data.forEach(field_id => {
        if (state.accountInfo[field_id] && state.accountInfo[field_id].value) {
          newCompletedRequired.push(field_id);
        }
      });

      newAccountInfoData = {
        ...state.accountInfo,
        requiredFields: data,
        completedRequired: newCompletedRequired,
      }

      newState = {
        ...state,
        accountInfo: {
          ...newAccountInfoData,
          valid: state.accountInfo.completedRequired.length === data.length ? true : false
        }
      }
      return newState;

    // case GETTING_PAYMENT_INTENT:
    //   data = action.data;

    //   newState = {
    //     ...state,
    //     paymentInfo: {
    //       ...state.paymentInfo,
    //       gettingPaymentMethod: false,
    //       gettingPaymentIntent: true
    //     }
    //   }

    //   return newState;

    case GET_INTENT_SECRET_SUCCESS:
      if (debug) console.log('GET_INTENT_SECRET_SUCCESS');
      data = action.payload.data;

      newState = {
        ...state,
        paymentInfo: {
          ...state.paymentInfo,
          gettingPaymentIntent: false,
          gettingPaymentMethod: true,
          intentClientSecret: data.client_secret
        }
      }

      return newState;

    case SET_PAYMENT_METHOD:
      if (debug)  console.log('SET_PAYMENT_METHOD');

      data = action.data;

      newState = {
        ...state,
        paymentInfo: {
          ...state.paymentInfo,
          paymentMethodId: data
        }
      }

      return newState;

    case SET_QUANTITY:
      if (debug) console.log("signup Reducer - SET_QUANTITY");

      data = action.data;
      subType = state.subscriptionType.subscription_type.value;
      products = state.products;
      newPlans = JSON.parse(JSON.stringify(state.paymentInfo.plans));
      let quantity = data.value;
      let foundPlan;

      foundPlan = newPlans.find(el => el.plan_type === data.planId);
      foundPlan.quantity = quantity;

      const quantityWithoutBulk = newPlans.reduce((acc, cv) => { 
        if (cv !== foundPlan) {
          acc += cv.quantity;
        }
        return acc;
      }, 0);

      const totalQuantity = quantityWithoutBulk + quantity;

      if (subType === 'multi-sub') {

        const currentMultiPlan = newPlans.find(el => Object.keys(multiPlans).includes(el.plan_type));

        if (!multiPlans[currentMultiPlan.plan_type].includes(totalQuantity)) {
          const newPlanId = Object.keys(multiPlans).find(el => multiPlans[el].includes(totalQuantity));
          const newProduct = products.find(el => el.plan_type === newPlanId);
          currentMultiPlan.plan_type = newProduct.plan_type;
          currentMultiPlan.price = newProduct.price;
          currentMultiPlan.stripe_product = newProduct;
        }
      }

      if (subType !== 'admin') {
        if (data.value === 0 && !['standard', 'formulary', 'bulk_23', 'bulk_45', 'bulk_69' ].includes(data.planId)) {
          const index = newPlans.indexOf(foundPlan);
          newPlans.splice(index, 1);
        }
      }

      newState = {
        ...state,
        paymentInfo: {
          ...state.paymentInfo,
          plans: newPlans
        }
      }
      return newState;

      
    case SUBSCRIPTION_SEND_ERROR:
      if (debug) console.log("signup Reducer - SUBSCRIPTION_SEND_ERROR");

      data = action.data;

      errors = [];

      if (data.error) {
        errors.push(data.error);
      } else {
        errors = [
          ...data.errors
        ]
      }

      newState = {
        ...state,
        paymentInfo: {
          ...state.paymentInfo,
          valid: false,
          submitting: false,
          gettingPaymentIntent: false,
          gettingPaymentMethod: false,
          intentClientSecret: null,
          errors,
        }
      }
      
      return newState;

    case SUBSCRIPTION_SEND_SUCCESS:
      if (debug) console.log("signup Reducer - SUBSCRIPTION_SEND_SUCCESS");

      data = action.payload.data;

      newState = {
        ...state,
        paymentInfo: {
          ...state.paymentInfo,
          done: true,
          userId: data.user_id
        }
      }
      return newState;
    
    case STEP_1_FIELD_CHANGE:
      if (debug) console.log("signup Reducer - STEP_1_FIELD_CHANGE");

      data = action.data;
      newSubTypeData = {
        ...state.subscriptionType,
        valid: data.value ? true : false
      }

      newSubTypeData[data.id] = {
        ...state.subscriptionType[data.id]
      };

      newSubTypeData[data.id]['value'] = data.value;

      newState = {
        ...state,
        subscriptionType: {
          ...newSubTypeData
        }
      }
      return newState;

    case STEP_2_FIELD_CHANGE:
      if (debug) console.log("signup Reducer - STEP_2_FIELD_CHANGE");

      data = action.data;
      let idIndex, error, errorMessage, newValid, newCompletedRequired;

      newCompletedRequired = [
        ...state.accountInfo.completedRequired
      ]
      // check if this field is required
      if (state.accountInfo.requiredFields.includes(data.key)) {
        if (data.value) {
          // check if the id already exists in completed array
          idIndex = newCompletedRequired.indexOf(data.key);
          // if it doesn't exist, add it to the array
          if (idIndex === -1) {
            newCompletedRequired.push(data.key);
          }
        } else {
          // check if the id already exists in completed array
          idIndex = newCompletedRequired.indexOf(data.key);
          // if it exists, remove it from the array since there is no value
          if (idIndex > -1) {
            newCompletedRequired.splice(idIndex, 1);
          }
        }
      }

      newValid = newCompletedRequired.length === state.accountInfo.requiredFields.length ? true : false;

      subType = state.subscriptionType.subscription_type.value;

      if (subType === 'vma-sub' && data.key === 'association_state' && data.value === 'other') {
        newValid = false;
        error = true;
        errorMessage = 'We are sorry, but a membership to your State or Province VMA is not yet able to receive a discount on VetCompanion.'
      }

      if (subType === 'rg-sub' && data.key === 'graduation_year' && data.value === 'other') {
        newValid = false;
        error = true;
        errorMessage = 'We are sorry, but recent graduate accounts are reserved for people who have graduated in the past 3 years.  Please <a href="/contact">contact us</a> for support.'
      }

      newAccountInfoData = {
        ...state.accountInfo,
        completedRequired: [
          ...newCompletedRequired,
        ],
        valid: newValid
      }

      newAccountInfoData[data.key] = {
        ...state.accountInfo[data.key],
        value: data.value,
        error: error ? error : false,
        errorMessage: errorMessage ? errorMessage : '',
      }

      newState = {
        ...state,
        accountInfo: {
          ...newAccountInfoData
        }
      }
      return newState;

    case STEP_3_FIELD_CHANGE:
      if (debug) console.log("signup Reducer - STEP_3_FIELD_CHANGE");

      data = action.data;

      newPaymentInfoData = {
        ...state.paymentInfo,
      }

      switch (data.key) {
        case 'join_year':
          newPaymentInfoData['addVMAYear'] = String(data.value);
          break;
        case 'association_state':
          newPaymentInfoData['addVMAState'] = data.value;
          break;
        default:
          newPaymentInfoData[data.key] = data.value;
          break;
      }

      if (data.key === 'promoCode') {
        newPaymentInfoData['promoError'] = '';
      }

      newState = {
        ...state,
        paymentInfo: {
          ...newPaymentInfoData
        }
      }
      return newState;
      
    case SET_STEP:
      if (debug) console.log("signup Reducer - SET_STEP");

      data = action.data;
      newProgressData  = {
        ...state.progress,
        step: data
      }
      return {
        ...state,
        progress: {
          ...newProgressData
        }
      };

    case TOGGLE_AGREEMENT:
      if (debug) console.log("signup Reducer - TOGGLE_AGREEMENT");

      return {
        ...state,
        paymentInfo: {
          ...state.paymentInfo,
          agreed: !state.paymentInfo.agreed
        }
      };

    case LOAD_SUBSCRIPTION_ACCOUNTINFO:

      const userData = action.data.userData;
      const prevProducts = action.data.prevProducts;

      const gradDate = userData.graduation_date;
      const gradYear = getYear(gradDate);
      const diff = differenceInMonths(new Date(), new Date(gradDate));

      switch (userData.subscription_type) {
        case 'single':
          subType = 'standard-sub'
          break;
        case 'vma':
        case 'new_vma':
          subType = 'vma-sub'
          break;
        case 'multi':
          subType = 'multi-sub'
          break;
        case 'student':
        case 'student_formulary':
          subType = 'student-sub'
          break;
        case 'recent_graduate_1':
        case 'recent_graduate_2':
        case 'recent_graduate_3':
          subType = 'rg-sub'
          break;
        default:
          subType = 'standard-sub'
      }

      return {
        ...state,
        previous_products: JSON.parse(prevProducts),
        subscriptionType: {
          ...state.subscriptionType,
          subscription_type: {
            value: subType
          }
        },
        accountInfo: {
          graduation_year: gradYear,
        }
      }
  
    default:
      return state;
  }
}
