import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SignUp from './SignUp';

import * as actions from './actions';
import * as sharedActions from '../Shared/actions';
let debug = false;

function mapStateToProps(state) {
  if (debug) console.log('SignUp - mapStateToProps');
  return {
    assocStates: state.globalReducer.assocStates,
    coupons: state.signupFormReducer.coupons,
    dialogData: state.globalReducer.dialog,
    globalErrors: state.globalReducer.errors,
    products: state.signupFormReducer.products,
    progressData: state.signupFormReducer.progress,
    section: state.globalReducer.section,
    step1Data: state.signupFormReducer.subscriptionType,
    step2Data: state.signupFormReducer.accountInfo,
    step3Data: state.signupFormReducer.paymentInfo,
    step4Data: state.signupFormReducer.finishScreen,
    userData: {},
    states: state.globalReducer.states,
  };
}

function mapDispatchToProps(dispatch) {
  if (debug) console.log('SignUp - mapDispatchToProps');
  const signupActions = bindActionCreators(actions, dispatch);
  const commonActions = bindActionCreators(sharedActions, dispatch);
  return {
    actions: {
      ...signupActions,
      ...commonActions,
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignUp);