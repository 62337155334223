import {createStore, compose, applyMiddleware} from 'redux';
import { createHashHistory } from 'history';
import createSagaMiddleware from 'redux-saga';
import rootReducer from '../reducers';
import rootSaga from 'sagas';
import thunk from 'redux-thunk';

const sagaMiddleware = createSagaMiddleware();

export const history = createHashHistory({
  hashType: 'slash',
  getUserConfirmation: (message, callback) => callback(window.confirm(message))
});

export default function configureStore() {
  const middlewares = [
    sagaMiddleware,
    thunk,
  ];

  const store =  createStore(
    rootReducer(history),
    compose(applyMiddleware(...middlewares))
  );

  sagaMiddleware.run(rootSaga);

  return store;
}