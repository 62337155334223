
import { call, takeLatest, select, put } from 'redux-saga/effects';
import callApi from './saga_helpers.js';

import { SAVE_ACCOUNT_INFO, CANCEL_SUBSCRIPTION, CANCEL_SUBSCRIPTION_TOGGLE } from 'components/MyAccount/Info/constants';

import { REQUEST_FAIL } from 'components/Shared/constants';

import myAccount from 'data/myAccount';

const API_URL = process.env.API_URL;
import url from 'url';

export function* saveAccountInfo() {
  let debug = false;
  const state = yield select();
  const queryURL = url.resolve(API_URL, `/settings`);
  const token = document.querySelector('[name=csrf-token]').content;
  const userData = state.myAccountFormReducer.user;
  const states = state.globalReducer.states;

  const accountFormFields = myAccount(states);

  const fieldsToSubmit = {}

  accountFormFields.forEach(fieldset => {
    if (!fieldset.fields) return;
    fieldset.fields.forEach(field => {
      if (userData[field.id] && userData[field.id].value) {
        fieldsToSubmit[field.id] = userData[field.id].value;
      }
    });
  });

  if (debug) console.log('sagas - saveAccountInfo');

  const opts = {
    method: 'PATCH',
    cache: 'no-cache',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': token,
    },
    body: JSON.stringify({user: fieldsToSubmit})
  };

  if (debug) console.log(opts);

  try {
    const payload = yield call(callApi, queryURL, opts, REQUEST_FAIL);

    if (debug) console.log(payload);

    if (payload) {
      // yield put({type: SAVE_ACCOUNT_INFO_SUCCESS, payload});
      window.location = '/settings/update_redirect';
    }
    
    return payload;

  } catch(error) {
    if (debug) console.log(error);
  }
}

export function* cancelSubscription(action) {
  let debug = true;
  const queryURL = url.resolve(API_URL, `/settings/cancel_subscription`);
  const token = document.querySelector('[name=csrf-token]').content;

  if (debug) console.log('sagas - cancelSubscription');

  const opts = {
    method: 'POST',
    cache: 'no-cache',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': token,
    },
    body: JSON.stringify({
      subscription_id: action.data
    })
  };

  if (debug) console.log(opts);

  try {
    const payload = yield call(callApi, queryURL, opts, REQUEST_FAIL);

    if (debug) console.log(payload);

    if (payload) {
      yield put({type: CANCEL_SUBSCRIPTION_TOGGLE, data: payload.data});
    }
    
    return payload;

  } catch(error) {
    if (debug) console.log(error);
  }

}

export const AccountInfoSagas = [
  takeLatest(SAVE_ACCOUNT_INFO, saveAccountInfo),
  takeLatest(CANCEL_SUBSCRIPTION, cancelSubscription),
]