import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router'
import signupFormReducer from './forms/signup';
import passwordFormReducer from './forms/password';
import myAccountFormReducer from './forms/myaccount';
import globalReducer from './global';

const rootReducer = (history) => combineReducers({
  router: connectRouter(history),
  signupFormReducer,
  passwordFormReducer,
  myAccountFormReducer,
  globalReducer,
});

export default rootReducer;
