import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';

import AndroidIcon from '@material-ui/icons/Android';
import AppleIcon from '@material-ui/icons/Apple';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  lastP: {
    marginTop: theme.spacing()
  },
  subButton: {
    '& .MuiButton-containedPrimary': {
      '&:hover': {
        color: theme.palette.primary.contrastText,
      },
      '&:focus': {
        color: theme.palette.primary.contrastText,
      },
      marginRight: theme.spacing()
    },
    marginTop: theme.spacing(),
    width: 'auto',
  },
  cancelButton: {
    padding: '6px 16px',
    '&:hover': {
      color: theme.palette.error.contrastText,
      backgroundColor: theme.palette.error.main,
    }
  },
  phoneButton: {
    marginRight: theme.spacing(),
    marginTop: theme.spacing()
  },
  custom: theme.customHr,
  sideSection: {
    [theme.breakpoints.up('lg')]: {
      marginTop: theme.spacing(6)
    }
  },
  personalMessage: {
    '& p': {
      marginBottom: theme.spacing(),
      marginTop: theme.spacing()
    }
  }
}));

function Side(props) {
  let debug = false;
  const classes = useStyles();

  if (debug) console.log(props);

  const [showingDialog, setshowingDialog] = useState(false);

  const {actions, subscriptionData, personalMessage, phoneInstructions, subscriptionCancelled, subscriptionRefunded, userData, futureSubscription, history } = props;

  useEffect(() => {
    if (!showingDialog && subscriptionCancelled) {
      setCancelledDialog();
      actions.toggleDialog();
      setshowingDialog(true);
    }
  });

  const handleAndroidClick = () => {
    setAndroidDialogInfo();
    actions.toggleDialog();
  };

  const handleAppleClick = () => {
    setAppleDialogInfo();
    actions.toggleDialog();
  };

  const setAndroidDialogInfo = () => {
    actions.setDialoInfo({
      title: 'Android Instructions',
      body: () => {
        return (
          <>
            <Typography variant="subtitle2">
              For easy access to the VetCompanion app, just follow these instructions:
            </Typography> <br />
            <div dangerouslySetInnerHTML={createMarkup(phoneInstructions.android.instructions)} />
            { renderImages(phoneInstructions.android.images) }
          </>
        )
      }
    });
  }

  const setAppleDialogInfo = () => {
    actions.setDialoInfo({
      title: 'iOS Instructions',
      body: () => {
        return (
          <>
            <Typography variant="subtitle2">
              For easy access to the VetCompanion app, just follow these instructions:
            </Typography><br />
            <div dangerouslySetInnerHTML={createMarkup(phoneInstructions.ios.instructions)} />
            { renderImages(phoneInstructions.ios.images) }
          </>
        )
      }
    });
  }

  const renderImages = (imageArray) => {
    const images = [];
    imageArray.forEach(image_url => {
      const image_tag = <div key={image_url} dangerouslySetInnerHTML={createMarkup(image_url)} />
      images.push(image_tag);
    });
    return images;
  }

  const renderFutureSubscriptionMessage = () => {
    if (futureSubscription) {
      return (
        <Typography className={classes.lastP} variant="body1" gutterBottom={false}>
          You have a future subscription scheduled to be billed on { format(new Date(futureSubscription.start_date), "PPP")}.
        </Typography>
      )
    }
  }
  
  const noButtonsSubArray = ['child', 'student', 'student_formulary', 'free', 'free_formulary', 'faculty', 'faculty_formulary', 'intern', 'intern_formulary'];

  const renderSubscriptionInfo = () => {
    if (userData)
    if (noButtonsSubArray.includes(userData.subscription_type.value)) return false;
    
    if (!userData.stripe_customer_id.value && userData.subscription_type.value !== 'inactive') return false;
    
    if (userData.subscription_type.value === 'inactive'
        || !subscriptionData
        || (subscriptionData && subscriptionData.status === 'cancelled' && !futureSubscription )) {
      return (
        <>
          <Button
            variant="contained"
            color="primary"
            href="/settings/subscribe"
            className={classes.subButton}
          >
            Re-activate My Subscription
          </Button>
        </>
      )
    }

    return (
      <>
        { renderSubscriptionMessage() }
        <Typography className={classes.lastP} variant="body1" gutterBottom={false}>
          { subscriptionData && subscriptionData.status === 'cancelled'
            ? ("")
            : <span><br /><br />Need to change your payment method?</span>
          }
        </Typography>

        {renderFutureSubscriptionMessage()}

        <Button
          variant="contained"
          color="primary"
          href="/settings/portal"
          className={classes.subButton}
        >
          Credit Card & Billing
        </Button>

        <Button
            variant="outlined"
            color="primary"
            target="_blank"
            href="/subscriber_agreement"
            className={classes.subButton}
          >
          Subscriber Agreement
        </Button>
        {
          (!subscriptionData || subscriptionData.status === 'cancelled') && subscriptionData.interval !== "year"
          ? ''
          : (
            <Button
              onClick={handleCancelClick}
              className={`${classes.subButton} ${classes.cancelButton}`}
            >
              Cancel Subscription
            </Button>
          )
        }
        <Divider className={classes.custom} />
      </>
    )
  }

  const renderSubscriptionMessage = () => {
    if (!subscriptionData) return false;
    const text = subscriptionData.status === 'cancelled' ? 'ends' : 'renews';
    return (
      <Typography variant="body1" gutterBottom={true}>
        Subscription {text} { format(new Date(subscriptionData.current_period_end), "PPP")}.
      </Typography>
    )
  }

  const setCancelledDialog = () => {
    actions.setDialoInfo({
      title: 'Subscription Cancelled',
      body: () => {
        return (
          <>
            <Typography variant="subtitle2">
              Your subscription has been cancelled.
            </Typography> <br />
            <Typography variant="body1" gutterBottom={true}>
              { subscriptionRefunded
                ? `You have been refunded and you will no longer have access to VetCompanion.`
                : `You will continue to have access to VetCompanion until your current subscription runs out on ${format(new Date(subscriptionData.current_period_end), "PPPP")}.`
              }
            </Typography>
          </>
        )
      }
    });
  }

  const createMarkup = (str) => {
    return {__html: str};
  }

  const handleCancelClick = () => {
    if (window.confirm('Are you sure you would like to cancel your subscription?')) {
      actions.cancelSubscription(subscriptionData.stripe_subscription_id)
    }
  }

  if (debug) console.log('Side RENDER');

  return (
    <div className={classes.sideSection}>
      <Typography variant="subtitle1" gutterBottom={true}>
        Add VetCompanion to your <br />phone or tablet:
      </Typography>
      <Button
        variant="contained"
        color="primary"
        className={classes.phoneButton}
        onClick={handleAndroidClick}
        startIcon={<AndroidIcon />}
      >
        Android
      </Button>
      <Button
          variant="contained"
          color="primary"
          className={classes.phoneButton}
          onClick={handleAppleClick}
          startIcon={<AppleIcon />}
        >
          iOS
      </Button>
      <Divider className={classes.custom} />
      { personalMessage
          ? <div className={classes.personalMessage}>
              <ReactMarkdown> {personalMessage} </ReactMarkdown>
              <Divider className={classes.custom} />
            </div>
          : null
      }
      { history.location.pathname.includes("in_")
        ? null
        : renderSubscriptionInfo()
      }
    </div>
  );
}

Side.propTypes = {
  actions: PropTypes.object.isRequired,
  subscriptionData: PropTypes.object,
  personalMessage: PropTypes.string.isRequired,
  phoneInstructions: PropTypes.object.isRequired,
};

export default Side;