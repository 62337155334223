import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Subscribe from './Subscribe';

import * as actions from './actions';
import * as sharedActions from 'components/Shared/actions';
let debug = false;

function mapStateToProps(state) {
  if (debug) console.log('Subscribe - mapStateToProps');
  return {
    assocStates: state.globalReducer.assocStates,
    coupons: state.signupFormReducer.coupons,
    defaultStep: 1,
    dialogData: state.globalReducer.dialog,
    globalErrors: state.globalReducer.errors,
    errors: state.globalReducer.errors,
    futureSubscription: state.globalReducer.future_subscription,
    personalMessage: state.myAccountFormReducer.personalMessage,
    phoneInstructions: state.myAccountFormReducer.phoneInstructions,
    products: state.signupFormReducer.products,
    progressData: state.signupFormReducer.progress,
    section: state.globalReducer.section,
    step1Data: state.signupFormReducer.subscriptionType,
    step3Data: state.signupFormReducer.paymentInfo,
    step4Data: state.signupFormReducer.finishScreen,
    subscriptionCancelled: state.myAccountFormReducer.subscriptionCancelled,
    subscriptionData: state.myAccountFormReducer.subscription,
    userData: state.myAccountFormReducer.user,
  };
}

function mapDispatchToProps(dispatch) {
  if (debug) console.log('Subscribe - mapDispatchToProps');
  const subscribeActions = bindActionCreators(actions, dispatch);
  const commonActions = bindActionCreators(sharedActions, dispatch);
  
  return {
    actions: {
      ...subscribeActions,
      ...commonActions,
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Subscribe);