export const BOOTSTRAP_PERSONAL_MESSAGE = 'BOOTSTRAP_PERSONAL_MESSAGE';
export const BOOTSTRAP_PHONE_INSTRUCTIONS = 'BOOTSTRAP_PHONE_INSTRUCTIONS';
export const BOOTSTRAP_SUBSCRIPTION = 'BOOTSTRAP_SUBSCRIPTION';
export const BOOTSTRAP_USER = 'BOOTSTRAP_USER';
export const MYACCOUNT_FIELD_CHANGE = 'MYACCOUNT_FIELD_CHANGE';
export const SET_ACCOUNT_REQUIRED = 'SET_ACCOUNT_REQUIRED';
export const SAVE_ACCOUNT_INFO = 'SAVE_ACCOUNT_INFO';
export const SAVE_ACCOUNT_INFO_SUCCESS = 'SAVE_ACCOUNT_INFO_SUCCESS';
export const CANCEL_SUBSCRIPTION = 'CANCEL_SUBSCRIPTION';
export const CANCEL_SUBSCRIPTION_TOGGLE = 'CANCEL_SUBSCRIPTION_TOGGLE';
export const GET_PROMO_CODE = 'GET_PROMO_CODE';