import {subYears, getMonth, getYear} from 'date-fns';

export default () => {
  
  const yearOptions = [];

  let startingDate;

  const todaysDate = new Date();

  if (getMonth(todaysDate) < 5) {
    startingDate = subYears(todaysDate, 1);
  } else {
    startingDate = todaysDate;
  }
  // index is to set the value of the drop down, which will help determining which grad year coupon to use
  let index = 0;
  const yearIndexMap = {};
  let i;

  for (i = 0; i > -3; i--) {
    index++
    const option_year = getYear(startingDate)
    yearOptions.push({ label: option_year, value: option_year});
    yearIndexMap[option_year] = (index).toString();
    startingDate = subYears(startingDate, 1);
  }

  yearOptions.push({ value: 'other', label: 'Other'})

  return [{
    options: {
      lg: [12],
      xs: [12],
    },
    fields: [ 
      {
        id: 'graduation_year',
        label: 'Graduation Year',
        description: '',
        required: true,
        input: 'select',
        indexMap: yearIndexMap,
        options: yearOptions,
      },
    ]
  }]
};