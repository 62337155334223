export const CHECK_PAYMENT_INFO_VALID = 'CHECK_PAYMENT_INFO_VALID';
export const SET_PAYMENT_METHOD = 'SET_PAYMENT_METHOD';
export const SET_STRIPE_VALID = 'SET_STRIPE_VALID';
export const START_GETTING_PAYMENT_METHOD = 'START_GETTING_PAYMENT_METHOD';
export const GET_INTENT_SECRET = 'GET_INTENT_SECRET';
export const GET_INTENT_SECRET_SUCCESS = 'GET_INTENT_SECRET_SUCCESS';

export const SET_SUBMITTING = 'SET_SUBMITTING';
export const SUBSCRIPTION_SEND = 'SUBSCRIPTION_SEND';

export const SUBSCRIPTION_SEND_ERROR = 'SUBSCRIPTION_SEND_ERROR';
export const SUBSCRIPTION_SEND_SUCCESS = 'SUBSCRIPTION_SEND_SUCCESS';

export const RESET_ERRORS = 'RESET_ERRORS';
export const REQUEST_FAIL = 'REQUEST_FAIL';

export const SET_DIALOG_INFO = 'SET_DIALOG_INFO';
export const TOGGLE_DIALOG = 'TOGGLE_DIALOG';

export const SET_BILLING_INTERVAL = 'SET_BILLING_INTERVAL';
export const SET_QUANTITY = 'SET_QUANTITY';

export const STEP_3_FIELD_CHANGE = 'STEP_3_FIELD_CHANGE';

export const GET_PROMO_CODE = 'GET_PROMO_CODE';
export const GET_PROMO_CODE_SUCCESS= 'GET_PROMO_CODE_SUCCESS';
export const GET_PROMO_CODE_FAIL = 'GET_PROMO_CODE_FAIL';
export const REMOVE_PROMO_CODE = 'REMOVE_PROMO_CODE';