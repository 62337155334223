export const ADD_RECENT_GRADUATE = 'ADD_RECENT_GRADUATE';
export const ADD_GRADUATE_PLAN_YEAR = 'ADD_GRADUATE_PLAN_YEAR';
export const ADD_VMA_STATE_YEAR = 'ADD_VMA_STATE_YEAR';
export const ADD_GRADUATE_PLAN_TO_INVOICE = 'ADD_GRADUATE_PLAN_TO_INVOICE';
export const ADD_VMA_PLAN_TO_INVOICE = 'ADD_VMA_PLAN_TO_INVOICE';

export const INITIATE_PLAN_ITEMS = 'INITIATE_PLAN_ITEMS';

export const BOOTSTRAP_COUPONS = 'BOOTSTRAP_COUPONS';
export const BOOTSTRAP_DEFAULT_STEP = 'BOOTSTRAP_DEFAULT_STEP';
export const BOOTSTRAP_DEFAULT_SUBSCRIPTION = 'BOOTSTRAP_DEFAULT_SUBSCRIPTION';
export const BOOTSTRAP_PRODUCTS = 'BOOTSTRAP_PRODUCTS';

export const CHECK_PAYMENT_INFO_VALID = 'CHECK_PAYMENT_INFO_VALID';
export const SET_STRIPE_VALID = 'SET_STRIPE_VALID';

// export const LOAD_USER_COUPON = 'LOAD_USER_COUPON';

export const GET_INVOICE = 'GET_INVOICE';
export const GET_INVOICE_BY_ID = 'GET_INVOICE_BY_ID';
export const GET_INVOICE_SUCCESS = 'GET_INVOICE_SUCCESS';

export const INVOICE_CHANGED = 'INVOICE_CHANGED';
export const SET_STEP_2_REQUIRED = 'SET_STEP_2_REQUIRED';

export const RESET_ERRORS = 'RESET_ERRORS';
export const REMOVE_ERRORS = 'REMOVE_ERRORS';

export const RESET_REQUIRED = 'RESET_REQUIRED';

export const STEP_1_FIELD_CHANGE = 'STEP_1_FIELD_CHANGE';
export const STEP_2_FIELD_CHANGE = 'STEP_2_FIELD_CHANGE';

export const SET_SUBSCRIPTION_TYPE = 'SET_SUBSCRIPTION_TYPE';
export const SET_STEP = 'SET_STEP';

export const STUDENT_EMAIL_CHECK = 'STUDENT_EMAIL_CHECK';
export const STUDENT_EMAIL_CHECK_SUCCESS = 'STUDENT_EMAIL_CHECK_SUCCESS';
export const STUDENT_EMAIL_CHECK_FAIL = 'STUDENT_EMAIL_CHECK_FAIL';

export const TOGGLE_AGREEMENT = 'TOGGLE_AGREEMENT';
export const LOAD_SUBSCRIPTION_ACCOUNTINFO = 'LOAD_SUBSCRIPTION_ACCOUNTINFO';

export const BOOTSTRAP_PREVIOUS_PRODUCTS = 'BOOTSTRAP_PREVIOUS_PRODUCTS';